/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import {
  validateCpf,
  validateEmail,
  isStringEightCharsLonger,
  validateName,
  validateDate
} from '../../../../helpers/validations';

export const handleFieldChange = (event, setFieldField) => {
  setFieldField(event.target.value);
}

export const validateEmailOrCpf = (setIsEmailValid, setIsCpfValid, emailOrCpfField) => {
  handleEmailValidation(setIsEmailValid, emailOrCpfField);
  handleCpfValidation(setIsCpfValid, emailOrCpfField);
}

export const handleCpfValidation = (setIsCpfValid, cpfField) => {
  setIsCpfValid(validateCpf(cpfField));
}

export const handleEmailValidation = (setIsEmailValid, emailField) => {
  setIsEmailValid(validateEmail(emailField));
}

export const isEmailOrCpfValid = (isEmailValid, isCpfValid) => {
  return isEmailValid || isCpfValid;
}

export const isEmailAndCpfInvalid = (isEmailValid, isCpfValid) => {
  if (isEmailValid === undefined && isCpfValid === undefined)
    return false;

  return !isEmailOrCpfValid(isEmailValid, isCpfValid);
}

export const validateConfirmPassword = (setIsConfirmPasswordValid, confirmPasswordField, passwordField) => {
  setIsConfirmPasswordValid(confirmPasswordField === passwordField);
}

export const validatePassword = (setIsPasswordValid, passwordField) => {
  setIsPasswordValid(isStringEightCharsLonger(passwordField));
}

export const handleShowPassword = (setShowPassword) => {
  setShowPassword((prevState) => !prevState);
}

export const isFieldValid = (isValid) => {
  return isValid;
}

export const isFieldInvalid = (isdValid) => {
  if (isdValid === undefined)
    return false;

  return !isFieldValid(isdValid);
}

export const validateNameField = (setIsFullNameValid, fullNameField) => {
  setIsFullNameValid(validateName(fullNameField.trim()));
}

export const handleValidateBirthDateField = (setIsBirthDateValid, birthDateField) => {
  setIsBirthDateValid(validateDate(birthDateField));
}