/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import UserContext from "../../Store/user-context";
import { useContext } from "react";
import "./Styles/SignIn.css"

function SignIn(props) {
  let type = props.type ? props.type : 1;
  let image = props.image ? props.image : "";
  let density = props.density ? props.density : "medium";
  let text = props.text ? props.text : "texto padrão";
  let disabled = props.disabled ? props.disabled : "";
  let handleLogin = props.handleLogin ? props.handleLogin : function () { };
  let confirmLogin = props.confirmLogin || function () { };

  const userContext = useContext(UserContext);

  if (type === 1) {
    return (
      <button
        onClick={handleLogin}
        className={`buttonSign-in${userContext.contrast}`}
        type="button"
        data-trigger={confirmLogin === 1 ? "login" : ""}
      >
        <i className="fas fa-user" aria-hidden="true" disabled={disabled}></i>
        {text}
      </button>
    );
  }

  if (image) {
    return (
      <div className="col-sm-auto mt-3 mt-sm-0">
        <button
          onClick={handleLogin}
          className={"br-button sign-in " + density}
          type="button"
          data-trigger={confirmLogin === 1 ? "login" : ""}
        >
          {text}&nbsp;
          <img src={image} alt={"gov.br"} disabled={disabled} />
        </button>
      </div>
    );
  }

  return (
    <div className="row mt-3">
      <div className="col-sm-auto">
        <button
          onClick={handleLogin}
          className={"br-button sign-in " + density}
          type="button"
          data-trigger={() => {
            if (confirmLogin === 1) return "login"
            return ""
          }}
          disabled={disabled}
        >
          {text}&nbsp;<span className="text-black">gov.br</span>
        </button>
      </div>
    </div>
  );
}

export default SignIn;
