import React from 'react'

function AlphaBar() {
  return (
    <div style={{
      backgroundColor: 'var(--color-danger)',
      color: 'var(--color-secondary-01)',
      padding: '0.5rem 1rem',
      fontSize: '1.2rem',
      fontWeight: '500',
      textAlign: 'center',
      top: 0,
      width: '100%',
    }}>
      Este é um site em desenvolvimento.
    </div>
  )
}

export default AlphaBar
