/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Store/user-context";
import { createSvgIcon } from '@mui/material/utils';
import "./Styles/Breadcrumb.css";

const HomeIcon = createSvgIcon(
  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />,
  'Home',

);

function Breadcrumb() {
  const userContext = useContext(UserContext);
  const [bread, setbread] = useState([])

  useEffect(() => {
    const breadcrumbList = userContext.getBreadLocation()
    let displaylist = []
    breadcrumbList.forEach((bl) => {
      if (bl.clickable === true) {
        displaylist.push(
          <div>
            <li key={bl.name} className="crumb"><i className="icon fas fa-chevron-right" id="seta_bc"></i>< a className={`${userContext.contrast}Text`} 
              href={bl.href} >{bl.name}</a>
            </li>
          </div>
        )
      } else {
        displaylist.push(
          <li key={bl.name} className="crumb"><i className="icon fas fa-chevron-right" id="seta_bc"></i><span className={`${userContext.contrast}Text_secondary`} >
            {bl.name}</span>
          </li>
        )
      }
    })
    setbread(displaylist)
    // eslint-disable-next-line
  }, [ userContext.getBreadLocation(), userContext.contrast])


  return (
    <div>
      <div className="container-fluid">
        <div className="br-breadcrumb pt-3" aria-label="Breadcumb">
            <ul className="crumb-list">
              <div><HomeIcon  className={`${userContext.contrast}Icon`}/></div>
              <li className="crumb"><i className="icon fas fa-chevron-right" id="seta_bc"></i><a className={`${userContext.contrast}Text`} href={"/"}>Inicio</a>
              </li>
              {bread.flatMap((bl) => {
                return (
                  bl
                )
              })}
            </ul>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb;