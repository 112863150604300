/*Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import { createContext, useState, useEffect } from 'react';



const NotificationContext = createContext({
  notification: null, // { title, message, status, position}
  showNotification: function (notificationData) { },
  hideNotification: function () { },
});



export function NotificationContextProvider(props) {
  const [activeNotification, setActiveNotification] = useState();
  const [loading, setloading] = useState(false);


  useEffect(() => {
    if (
      activeNotification &&
      (activeNotification.status === 'success' ||
        activeNotification.status === 'error' ||
        activeNotification.status === 'warning' ||
        activeNotification.status === 'loading' ||
        activeNotification.status === 'info')
    ) {
      activeNotification.status === 'loading' ? setloading(true) : setloading(false);

      const timer = setTimeout(() => {
        setActiveNotification(null);
      }, 3000);

      return () => {
        clearTimeout(timer);
        setloading(false);
      };
    }
  }, [activeNotification]);

  function showNotificationHandler(notificationData) {
    setActiveNotification(notificationData);
  }

  function hideNotificationHandler() {
    setActiveNotification(null);
  }

  const context = {
    notification: activeNotification,
    showNotification: showNotificationHandler,
    hideNotification: hideNotificationHandler,
  };

  useEffect(() => {
    if (loading) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [loading]);


  return (
    <NotificationContext.Provider  value={context}>
      {props.children}


      {activeNotification && loading && (

        <div  style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(10px) ',
          backgroundColor: 'rgba(250, 250, 250, 0.5)',
          zIndex: '9998',
        }}>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#fff',
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '9999',
            }}
          >
          <div class="loading medium"></div>  

          <p style={{
            position: 'absolute',
            top: '120%',
            left: '50%',
            color:  '#1351b4',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            transform: 'translate(-50%, -50%)',
          }}> Carregando... </p>
            
          </div>
        </div>
 
      )}
      
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
