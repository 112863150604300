import * as regioes from "../regioes.json";
import DataFrame from "dataframe-js";

let header = [
  "municipio_id",
  "municipio_nome",
  "mesorregiao_id",
  "mesorregiao_nome",
  "regiao_id",
  "regiao_nome",
  "estado_id",
  "estado_nome",
];

const df = new DataFrame(regioes.default, header);

df.sql.register("TabelaRegioes", true);

/**
 * Request all the regions from the dataframe (currently)
 * @returns all regions in database
 */
export function getRegion() {
  const regions = DataFrame.sql
    .request(`SELECT regiao_id, regiao_nome FROM TabelaRegioes`)
    .dropDuplicates("regiao_id", "regiao_nome")
    .sortBy("regiao_id")
    .toArray();
  return regions;
}

/**
 * Object with all the regions
 */
export const regionsObj = getRegion()

/**
 * Request the UF's from the dataframe (currently) based on the selected regions
 * @param {Array} region - The array of regions selected
 * @returns - All the UF's in the selected regions
 */
export function getUf(region) {
  const ufs = DataFrame.sql
    .request(
      `SELECT estado_id, estado_nome FROM TabelaRegioes WHERE regiao_id IN (${region})`
    )
    .dropDuplicates("estado_id", "estado_nome")
    .sortBy("estado_nome")
    .toArray();

  return ufs;
}

/**
 * Request the mesoregions from the dataframe (currently) based on the array of selected UF's
 * @param {Array} uf - The array of UF's selected
 * @returns - All the mesoregions in the selected UF's
 */
export function getMesoregion(uf) {
  const mesoregion = DataFrame.sql
    .request(
      `SELECT mesorregiao_id, mesorregiao_nome, estado_nome FROM TabelaRegioes WHERE estado_id IN (${uf})`
    )
    .dropDuplicates("mesorregiao_id", "mesorregiao_nome", "estado_nome")
    .sortBy("mesorregiao_id")
    .toArray();
  return mesoregion;
}

/**
 * Request the cities from the dataframe (currently) based on the parameter: region, uf or mesoregion
 * @param {Array} mesoregion - The array of mesoregions selected
 * @param {Array} uf - The array of UF's selected
 * @param {Array} region - The array of regions selected
 * @returns - The array of cities in the non-null parameter
 */
export function getCity(mesoregion, uf = "", region = "") {
  let cities;

  if (mesoregion !== "") {
    cities = DataFrame.sql
      .request(
        `SELECT municipio_id, municipio_nome FROM TabelaRegioes WHERE  mesorregiao_id IN '${mesoregion}'`
      )
      .dropDuplicates("municipio_id", "municipio_nome")
      .sortBy("municipio_nome")
      .toArray();
  } else if (uf !== "") {
    cities = DataFrame.sql
      .request(
        `SELECT municipio_id, municipio_nome FROM TabelaRegioes WHERE  estado_nome IN '${uf}'`
      )
      .dropDuplicates("municipio_id", "municipio_nome")
      .sortBy("municipio_nome")
      .toArray();
  } else {
    cities = DataFrame.sql
      .request(
        `SELECT municipio_id, municipio_nome FROM TabelaRegioes WHERE  regiao_id IN '${region}'`
      )
      .dropDuplicates("municipio_id", "municipio_nome")
      .sortBy("municipio_nome")
      .toArray();
  }
  return cities;
}

/**
 * Generate an array of keys of all regions and mesoregions to use in the chart
 * @returns - array of keys used in the chart
 */
export function getKeys() {
    let keys = [];
    keys[0] = regionsObj.flatMap((region) => {
      return {
        id: region[0],
        name: region[1],
      };
    });
    keys[1] = DataFrame.sql
      .request(`SELECT mesorregiao_id, mesorregiao_nome FROM TabelaRegioes`)
      .dropDuplicates("mesorregiao_id", "mesorregiao_nome")
      .sortBy("mesorregiao_id")
      .toArray()
      .flatMap((mesoregion) => {
        return {
          id: mesoregion[0],
          name: mesoregion[1],
        };
      });
    return keys;
  }

export {};
