/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import MaterialTable from "material-table";
import UserContext from "../../../Store/user-context";
import {
  retrieveStyle,
  retrieveIcons,
  Local,
  retrieveOpts,
  retrieveActionSearch,
} from "./configuration";
import * as apiFunctions from "../../../Store/apiFunctions.js";
import { generateNotesList } from "./tableManagementFunctions";
import api_configuration from "../../../api_configuration";
import DescriptionCardModal from "../modals/filter-modal";
import List from "../../ds-gov/List";
import "./management.css";
import Input from "../../ds-gov/Input";
import Select from "../../ds-gov/Select";
import lodash from "lodash";

function UserManagement(props) {
  let shouldUpdate = props.shouldUpdateData || false;
  let setShouldUpdate = props.setShouldUpdateData || function () {};
  // eslint-disable-next-line
  let usersData = props.data || [];
  let roleId = props.roleId || 0;
  const [loading, setLoading] = useState();
  const userContext = useContext(UserContext);
  const [data, setData] = useState(usersData);
  const [notes, setNotes] = useState({
    list: [],
  });
  const [userName, setUserName] = useState("");
  const [userRoleId, setUserRoleId] = useState("");
  const [lastCheckBox, setLastCheckBox] = useState(undefined);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [newNote, setNewNote] = useState("");
  const [openUserModal, setOpenUserModal] = useState(false);
  const [toggleSearch, setSearch] = useState(false);
  const [currentUser, setCurrentUser] = useState({ notesArray: [] });
  const [showSelect, setShowSelect] = useState(true);
  const local = Local;
  let Tstyle = props.tstyle || retrieveStyle(userContext.contrast);
  let icons = retrieveIcons(userContext.contrast);
  const opts = retrieveOpts(Tstyle, toggleSearch);
  const actionSearch = props.iconSearch || retrieveActionSearch(userContext);
  const [isBeingResized, setIsBeingResized] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const acts = [
    {
      icon: actionSearch,
      tooltip: "busca",
      isFreeAction: true,
      onClick: (event) => setSearch(!toggleSearch),
    },
    {
      icon: "edit",
      tooltip: "",
      onClick: (event, user) => {
        getUserInfo(user);
        setShowSelect(!showSelect);
      },
    },
  ];

  useEffect(() => {
    if (loading === false) {
      setShouldUpdate(!shouldUpdate);
    }
  });

  useEffect(() => {
    userContext.closeAccessibility();
    apiFunctions.Get(api_configuration.api_route.roles_api, setRolesOptions);
    // eslint-disable-next-line
  }, []);

  window.onbeforeunload = function () {
    userContext.toggleAccessibility();
  };

  function getUserInfo(user) {
    setNotes([generateNotesList(user, setLoading)]);
    setCurrentUser(user);
    setOpenUserModal(!openUserModal);
  }

  useEffect(() => {
    setData(usersData);
    setNotes([generateNotesList(currentUser, setLoading)]);
    // eslint-disable-next-line
  }, [usersData, shouldUpdate]);

  let header_list;
  let title;

  title = "Usuários";
  header_list = [
    {
      field: "nome",
      title: "Nome",
      validate: (rowData) => rowData.nome !== "",
    },
    {
      field: "email",
      title: "Email",
      validate: (rowData) => rowData.email !== "",
    },
    {
      field: "CPF",
      title: "CPF",
      validate: (rowData) => rowData.CPF !== "",
    },
    {
      field: "permission",
      title: "Permissões",
      lookup:
        roleId === 5
          ? {
              1: "Usuário Público",
              2: "Técnico",
              3: "Analista",
              4: "Gerente",
              5: "Administrador",
            }
          : {
              1: "Usuário Público",
              2: "Técnico",
              3: "Analista",
              4: "Gerente",
            },
    },
    {
      field: "notes",
      title: "Anotações",
      validate: (rowData) => rowData.notes !== "",
    },
  ];

  useEffect(() => {
    setCpf(currentUser.CPF);
    setEmail(currentUser.email);
    setUserName(currentUser.nome);
    let roleObj;
    let map = rolesOptions.map((role) => {
      if (role.role_name === currentUser.role_name) {
        roleObj = {
          id: role.id,
          name: role.role_name,
          checked: true,
        };
        return roleObj;
      }
      return {
        id: role.id,
        name: role.role_name,
        checked: false,
      };
    });
    const found = (role) => roleObj === role;
    setLastCheckBox(map.findIndex(found));
    setCheckedRoles(map);
    // eslint-disable-next-line
  }, [currentUser]);

  function handleChangeRole(checkboxIndex) {
    const checkedArray = [...checkedRoles];
    if (checkedArray[checkboxIndex] !== lastCheckBox) {
      checkedArray[checkboxIndex].checked =
        !checkedArray[checkboxIndex].checked;
      checkedArray[lastCheckBox].checked = false;
      setLastCheckBox(checkboxIndex);
      setCheckedRoles([...checkedArray]);
      setUserRoleId(checkedArray[checkboxIndex].id);
    }
  }
  // eslint-disable-next-line
  const reset = useCallback(
    lodash.debounce(() => {
      setIsBeingResized(false);
    }, 200),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [setIsBeingResized]);

  useEffect(() => {
    setIsBeingResized(true);
    reset();
    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (showSelect === false) setShowSelect(true);
    // eslint-disable-next-line
  }, [showSelect]);

  let modalContent = showSelect && (
    <div
      style={
        window.innerWidth < 680 && !isBeingResized ? { margin: "1rem" } : {}
      }
    >
      <div
        style={
          window.innerWidth < 680 && !isBeingResized
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }
            : {
                display: "flex",
                gap: "0.5rem",
                justifyContent: "center",
                flexDirection: "row",
              }
        }
      >
        <div style={{ width: "100%" }}>
          <Input
            inputPlaceholder={"Nome"}
            labelText={"Nome"}
            inputValue={userName}
            labelFor={"name"}
            inputId={"name"}
            handleChange={(e) => setUserName(e.target.value)}
            onBlur={() => {}}
          />
        </div>
        <div style={{ height: "1rem", width: "1rem" }} />
        <div style={{ width: "100%" }}>
          <Input
            inputPlaceholder={"CPF"}
            labelText={"CPF"}
            inputValue={cpf}
            labelFor={"CPF"}
            inputId={"CPF"}
            handleChange={(e) => setCpf(e.target.value)}
            onBlur={() => {}}
          />
        </div>
      </div>
      <div style={{ height: "2rem" }} />
      {showSelect && (
        <div
          style={
            window.innerWidth < 680 && !isBeingResized
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }
              : {
                  display: "flex",
                  gap: "0.5rem",
                  justifyContent: "center",
                  flexDirection: "row",
                }
          }
        >
          <div style={{ width: "100%" }}>
            <Select
              label={"Permissão"}
              placeholder={"Selecione a permissão"}
              handleChange={handleChangeRole}
              options={checkedRoles}
            />
          </div>
          <div
            style={
              window.innerWidth < 680 && !isBeingResized
                ? { height: "1rem", width: "1rem" }
                : { height: "1rem", width: "1rem" }
            }
          />
          <div style={{ width: "100%" }}>
            <Input
              inputPlaceholder={"Email"}
              labelText={"Email"}
              inputValue={email}
              labelFor={"email"}
              inputId={"email"}
              handleChange={(e) => setEmail(e.target.value)}
              onBlur={() => {}}
            />
          </div>
        </div>
      )}
      <div style={{ height: "2rem" }} />
      <Input
        inputPlaceholder={"Anotação sobre o usuário"}
        labelText={"Anotação"}
        inputValue={newNote}
        labelFor={"note"}
        inputId={"note"}
        handleChange={(e) => setNewNote(e.target.value)}
        onBlur={() => {}}
      />
      <div style={{ height: "2rem" }} />
      {currentUser.notesArray.length === 0 && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p className="user-category">Anotações:&nbsp;</p>
          <p>{currentUser.notes}</p>
        </div>
      )}
      <div>
        {currentUser.notesArray.length > 0 && (
          <List divide={true} expandable={true} content={notes} />
        )}
      </div>
    </div>
  );
  function saveUser() {
    /* verify if there is change in user properties, then patch the new user in database and update the data state */
    if (
      (userRoleId !== "" && currentUser.permission !== userRoleId) ||
      currentUser.email !== email ||
      currentUser.nome !== userName ||
      currentUser.CPF !== cpf
    ) {
      let body = {
        role_id: Number(userRoleId) || currentUser.permission,
        name: userName,
        email: email,
        cpf: cpf,
      };
      apiFunctions.Patch(
        api_configuration.api_route.user_api + currentUser.id,
        body,
        undefined,
        setLoading
      );
    }
    /* verify if there is change in the user notes, then Post the new note in database and update the data state */
    if (newNote !== "") {
      let body = {
        user_id: currentUser.id,
        creator_id: JSON.parse(localStorage.getItem("user")).id,
        note: newNote,
      };
      apiFunctions.Post(
        api_configuration.api_route.user_notes_api,
        body,
        undefined,
        setLoading
      );
    }
  }

  return (
    <div className={`user-container ${userContext.contrast}`}>
      <DescriptionCardModal
        open={openUserModal}
        onHandle={() => {
          setOpenUserModal(!openUserModal);
        }}
        label={"Salvar"}
        icon={"none"}
        onSave={saveUser}
        description={modalContent}
      />
      <MaterialTable
        title={title}
        columns={header_list}
        data={data}
        actions={acts}
        options={opts}
        localization={local}
        style={Tstyle}
        icons={icons}
      />
    </div>
  );
}

export default UserManagement;
