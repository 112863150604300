/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRList from "./js-dsgov/List";
import "./Styles/List.css";
import { useEffect, useState } from "react";

/*  
  Parameters:
  * title: string, 
  * divide: boolean,
  * horizontal: boolean,
  * compId: string,
  * labeled: boolean,
  * expandable: boolean,
  * content: array of objects({
    if labeled or expandable is true:
      * label: string,
      * list: {primaryText: String, secondaryText: String, icon: String},
    else 
      {primaryText: String, secondaryText: String, icon: String},
  }),
  * contrast: String,
*/

function List(props) {
  // LAST : opcional: data-toggle e data-unique para lista com expansão
  let fullPage = props.fullPage || false;
  let title = props.title ? props.title : "";
  let divide = props.divide ? props.divide : false;
  let horizontal = props.horizontal ? props.horizontal : false;
  let compId = props.compId ? props.compId : "list-default";
  let content = props.content ? props.content : [];
  let labeled = props.labeled ? props.labeled : false;
  let expandable = props.expandable ? props.expandable : false;
  let contrast = props.contrast ? props.contrast : "";
  const listList = [];
  useEffect(() => {
    for (const brList of window.document.querySelectorAll("#" + compId)) {
      listList.push(new BRList("br-list-collapsible", brList));
    }
    for (const brList of window.document.querySelectorAll(
      ".br-list[data-checkable]"
    )) {
      listList.push(new BRList("br-list-checkable", brList));
    }
    // eslint-disable-next-line
  }, []);

  const [isOpen, setIsOPen] = useState({
    index: -1,
    isOpen: false,
  });
  return (
    <div
      className={
        horizontal ? `br-list horizontal ${contrast}` : `br-list ${contrast}`
      }
      role="list"
    >
      <div className={title ? "header" : ""}>
        <div className={title ? "title" : ""}>{title}</div>
      </div>
      <span className={title ? "br-divider" : ""}></span>
      {expandable &&
        content.map((item, index) => {
          return (
            <div
              key={new Date().toISOString() + index}
              className={`br-accordion ${contrast}`}
            >
              <div
                key={`div${item}`}
                className={title ? `item ${contrast}` : ""}
              >
                <button
                  key={`btn${item + index}`}
                  className={`header ${contrast}`}
                  onClick={() => {
                    if (isOpen.index === index)
                      setIsOPen({
                        index: -1,
                        isOpen: false,
                      });
                    else
                      setIsOPen({
                        index: index,
                        isOpen: true,
                      });
                  }}
                >
                  {item.label}
                  {typeof item.total === "number" &&
                    isFinite(item.total) &&
                    item.total >= 0 &&
                    Number.isInteger(item.total) &&
                    ` (${item.total})`}
                  <i
                    key={`icon${item}`}
                    className="fas fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div>
                {isOpen.index === index && isOpen.isOpen && (
                  <div
                    style={{
                      height: fullPage ? "auto" : 150,
                      overflow: "auto",
                    }}
                  >
                    {item.list.map((listItem, index) => {
                      return (
                        <div key={new Date().toISOString() + index}>
                          <div
                            key={new Date().toISOString() + index + index}
                            className={`align-items-center br-item py-3 ${contrast}`}
                            role="listitem"
                          >
                            <div
                              key={
                                new Date().toISOString() + index + index + index
                              }
                              className="row align-items"
                            >
                              <div
                                key={
                                  new Date().toISOString() + listItem + index
                                }
                                className="col-auto"
                              >
                                <i
                                  style={listItem.onClick ? {cursor: "pointer"} : {}}
                                  onClick={listItem.onClick}
                                  key={`isopen${item}`}
                                  className={listItem.icon}
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="col">{listItem.primaryText}</div>
                              <div className="col-auto">
                                {listItem.secondaryText}
                              </div>
                            </div>
                          </div>
                          {divide && <span className="br-divider"></span>}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      {labeled &&
        content.map((item, index) => {
          return (
            <div key={new Date().toISOString() + index}>
              <div
                className={`align-items-center br-item ${contrast}`}
                role="listitem"
              >
                {item.label}
              </div>
              {item.list.map((listItem, index) => {
                return (
                  <div key={new Date().toISOString() + index}>
                    <div
                      className={`align-items-center br-item py-3 ${contrast}`}
                      role="listitem"
                    >
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <i className={listItem.icon} aria-hidden="true"></i>
                        </div>
                        <div className="col">{listItem.primaryText}</div>
                        <div className="col-auto">{listItem.secondaryText}</div>
                      </div>
                    </div>
                    {divide && <span className="br-divider"></span>}
                  </div>
                );
              })}
            </div>
          );
        })}
      {!labeled &&
        !expandable &&
        content.map((item, index) => {
          return (
            <div key={new Date().toISOString() + index}>
              <div
                className={`align-items-center br-item py-3 ${contrast}`}
                role="listitem"
                key={new Date().toISOString() + index}
              >
                <div className="row align-items-center">
                  <div className="col-auto">
                    <i className={item.icon} aria-hidden="true"></i>
                  </div>
                  <div className="col">{item.primaryText}</div>
                  <div className="col-auto">{item.secondaryText}</div>
                </div>
              </div>
              {divide && <span className="br-divider"></span>}
            </div>
          );
        })}
    </div>
  );
}

export default List;
