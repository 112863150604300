/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import govbr from "../../images/govbr.png"
import acesso from "../../images/acesso.png"
import logopatria from "../../images/Modo_escuro.png"
import logopatria_claro from "../../images/Modo_claro.png"
import React, { useEffect, useContext } from 'react';
import BRFooter from "./js-dsgov/BRFooter";
import List from "./List";
import "./Styles/Footer.css";
import UserContext from "../../Store/user-context";


function Footer(props) {
  const listFooter = []
  useEffect(() => {
    for (const brFooter of window.document.querySelectorAll('.br-footer')) {
      listFooter.push(new BRFooter('br-footer', brFooter))
    }
    // eslint-disable-next-line
  }, [])

  const userContext = useContext(UserContext);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);



  let social_1 = [
    {
      link: "https://www.youtube.com/FNDEMEC",
      image:
        "fab fa-youtube-square fa-3x",
    },
    {
      link: "https://www.flickr.com/photos/fndemec1",
      image:
        "fab fa-flickr fa-3x",
    },
    {
      link: "https://www.facebook.com/fnde.educacao",
      image:
        "fab fa-facebook-square fa-3x",
    },
    {
      link: "https://twitter.com/fndeoficial",
      image:
        "fab fa-twitter-square fa-3x"
    },
  ];

  let license = (
    <div className="text-down-01 text-medium pb-3 text-center">
      Texto destinado a exibição das informações relacionadas à&nbsp;
      <strong>licença de uso.</strong>{" "}
    </div>
  );

  const category1 = [
    {
      label: <p className={dimensions.width <= 962 ? `${userContext.contrast}subject` : `${userContext.contrast}subject subject_a`}>
        ASSUNTOS</p>,
      list: [
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/assuntos/noticias">Notícias</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/assuntos/sistemas">Sistemas</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://email.fnde.gov.br/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2femail.fnde.gov.br%2fowa%2f">Webmail</a>,
          secondaryText: "",
          icon: "",
        },
      ],
    },
  ]

  const category2 = [
    {
      label: <p className={`${userContext.contrast}subject`}>ACESSO À INFORMAÇÃO</p>,
      list: [
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/institucional">Institucional</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/agenda-de-autoridades">Agenda de Autoridades</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/auditorias-1">Auditorias</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/acoes-e-programas">Ações e Programas</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/transparencia-e-prestacao-de-contas-2">Transparência e Prestação de <br /> Contas</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/comissao-de-etica">Comissão de Ética</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/governanca-1">Governança</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/participacao-social">Participação Social</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.portaltransparencia.gov.br/convenios/">Convênios e Transferências</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.portaltransparencia.gov.br/despesasdiarias/">Receitas e Despesas</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.fnde.gov.br/index.php/acoes/compras-governamentais">Licitações e Contratos</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.portaldatransparencia.gov.br/servidores/orgao?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&orgaosServidorExercicio=OR26106&colunasSelecionadas=linkDetalhamento%2CorgaoSuperiorExercicioSIAPE%2CorgaoExercicioSIAPE%2CquantidadePessoas%2CquantidadeVinculos%2CunidadeOrganizacionalServidorLotacao%2CorgaoLotacaoSIAPE&ordenarPor=orgaoSuperiorExercicioSIAPE&direcao=asc">Servidores</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/informacoes-classificadas">Informações Classificadas em Grau <br /> de Sigilo</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/sic-servico-de-informacao-ao-cidadao">Serviço de Informação ao Cidadão (SIC)</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/perguntas-frequentes">Perguntas Frequentes</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/dados-abertos">Dados Abertos</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/acao-de-desenvolvimento-de-pessoas">Ação de Desenvolvimento de Pessoas</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/acesso-a-informacao/legislacao">Legislação</a>,
          secondaryText: "",
          icon: "",
        }
      ],
    },
  ];

  const category3 = [
    {
      label: <p className={`${userContext.contrast}subject`}>COMPOSIÇÃO</p>,
      list: [
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/Presidente">Presidência</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/procuradoria-federal-junto-ao-fnde-pf-fnde">Procuradoria Federal Junto ao <br /> FNDE - PF/FNDE</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/auditoria-interna-audit">Auditoria Interna - Audit</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-de-administracao-dirad">Diretoria de Administração - DIRAD</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-de-tecnologia">Diretoria de Tecnologia e Inovação - DIRTI</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-financeira-difin">Diretoria Financeira - DIFIN</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-de-acoes-educacionais-dirae">Diretoria de Ações Educacionais -  DIRAE</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-de-gestao-articulacao-e-projetos-educacionais-digap">Diretoria de Gestão Articulação  e <br /> Projetos Educacionais - DIGAP</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/composicao/diretoria-de-gestao-de-fundos-e-beneficios-digef">Diretoria de Gestão de Fundos e <br /> Benefícios - DIGEF</a>,
          secondaryText: "",
          icon: "",
        },
      ],
    },
  ];

  const category4 = [
    {
      label: <p className={dimensions.width <= 962 ? `${userContext.contrast}subject` : `${userContext.contrast}subject subject_b`}>
        CENTRAIS DE CONTEÚDO</p>,
      list: [
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.flickr.com/photos/fndemec1/">Imagens</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.youtube.com/user/FNDEMEC">Vídeos</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/centrais-de-conteudo/redes-sociais-canais">Redes Sociais</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/centrais-de-conteudo/publicacoes">Publicações</a>,
          secondaryText: "",
          icon: "",
        },
      ],
    },
  ]

  const category5 = [
    {
      label: <p className={dimensions.width <= 962 ? `${userContext.contrast}subject` : `${userContext.contrast}subject subject_b`}>
        CANAIS DE ATENDIMENTO</p>,
      list: [
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/canaistendimento/ouvidoria">Ouvidoria</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/canais_atendimento/imprensa">Imprensa</a>,
          secondaryText: "",
          icon: "",
        },
        {
          primaryText: <a className={`${userContext.contrast}topic`} href="https://www.gov.br/fnde/pt-br/canais_atendimento/fale-conosco">Fale Conosco</a>,
          secondaryText: "",
          icon: "",
        },
      ],
    },
  ]

  let contrast_list = userContext.contrast === "contrast" ? " " : "contrast";

  return (
    <footer className={`${userContext.contrast}br-fter`}>
      <div >
        <div className="logo" inverted="inverted">
          {<img className={`${contrast_list}img_gov`} src={govbr} alt="Logo Governo" />}
        </div>

        <div className="links">
          {dimensions.width <= 962 ?

            <div className="group">
              <div><List horizontal={false} divide={false} contrast={`${contrast_list}`} expandable={true} content={category1} /></div>
              <div><List horizontal={false} divide={false} contrast={`${contrast_list}`} expandable={true} content={category2} /></div>
              <div><List horizontal={false} divide={false} contrast={`${contrast_list}`} expandable={true} content={category3} /></div>
              <div><List horizontal={false} divide={false} contrast={`${contrast_list}`} expandable={true} content={category4} /></div>
              <div><List horizontal={false} divide={false} contrast={`${contrast_list}`} expandable={true} content={category5} /></div>
            </div> :

            <div className="group-2">
              <div className="element"><List horizontal={false} contrast={`${contrast_list}`} labeled={true} content={category1} /></div>
              <div><List horizontal={false} contrast={`${contrast_list}`} labeled={true} content={category2} /></div>
              <div><List horizontal={false} contrast={`${contrast_list}`} labeled={true} content={category3} /></div>
              <div className="element"><List horizontal={false} contrast={`${contrast_list}`} labeled={true} content={category4} /></div>
              <div><List horizontal={false} contrast={`${contrast_list}`} labeled={true} content={category5} /></div>
            </div>
          }
        </div>

        <div className="footer_block">
          <div className="social-network">
            {social_1.map((item, index) => (
              <a className={`${contrast_list}margin-3`} href={item.link} key={item.link + index}>
                <i class={item.image} />
              </a>
            ))}
            <a className={`${contrast_list}box-instagram`} href={"https://www.instagram.com/accounts/login/?next=/fnde.oficial/"} >
              <i class="fab fa-instagram fa-2x" style={
                `${contrast_list}` === "contrast"
                  ? { color: '#071D41' }
                  : { color: '#ffffff' }
              } />
            </a>
          </div>

          <div className="gov">
            <a className="mr-3" href={"https://www.gov.br/acessoainformacao/pt-br"}>
              <img className={`${contrast_list}img_acesso`} src={acesso} alt="description" />
            </a>

            <div style={dimensions.width <= 962 ? { width: "0px" } : { width: "40px" }} />

            <a className="mr-3" href={"https://www.gov.br/pt-br"}>
              <img className={`${contrast_list}img_social`} src={
                `${contrast_list}` === "contrast" ?
                  logopatria : logopatria_claro
              } alt="description" />
            </a>
          </div>
        </div>

      </div>
      <span className="br-divider my-3"></span>
      <div className="container-lg">
        <div className={`${contrast_list}info`}>{license}</div>
      </div>
    </footer >
  );
}

export default Footer;
