/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from 'react';
import './Toast.css';
import NotificationContext from '../../../Store/notification-store';
import CheckImage from '../../../images/toast/check.svg'
import ErrorImage from '../../../images/toast/error.svg';
import WarningImage from '../../../images/toast/warning.svg';
import InfoImage from '../../../images/toast/info.svg';

/*  Parameters 
 * -title: String, 
 * -message: String,
 * -status: String, 
 * -position: String */

const Toast = props => {
  const notificationCtx = useContext(NotificationContext);
  const { title, message, status, position } = props;

  return (
    <>
      <div key={"bg"} className={`notification-container ${position}`} onClick={notificationCtx.hideNotification}>
        <div
        key={"notification"}
          className={`notification toast ${position}`}
          style={
            status === 'success' ? { backgroundColor: 'var(--color-success)' } :
              status === 'error' ? { backgroundColor: 'var(--color-danger)' } :
                status === 'warning' ? { backgroundColor: 'var(--color-warning)' } :
                  { backgroundColor: 'var(--color-info)' }
          }
        >
          <button>
            <i className="fas fa-times-circle"></i>
          </button>
          <div key="notifcationImage" className="notification-image">
            {
              status === 'success' && <img src={CheckImage} alt="success" />
            }
            {
              status === 'error' && <img src={ErrorImage} alt="error" />
            }
            {
              status === 'warning' && <img src={WarningImage} alt="warning" />
            }
            {
              status === 'info' && <img src={InfoImage} alt="warning" />
            }
          </div>
          <div key="notificationTitle">
            <p className="notification-title">
              {title}
            </p>
            <p className="notification-message">
              {message}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Toast;