/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from "react";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import UserContext from "../../../Store/user-context";
import {
  retrieveStyle,
  retrieveIcons,
  Local,
  retrieveActionSearch,
  retrieveOpts,
} from "./configuration";
import * as tableFunctions from "./tableManagementFunctions";
import * as apiFunctions from "../../../Store/apiFunctions";
import "./management.css";
import api_configuration from "../../../api_configuration";
import Input from "../../ds-gov/Input";
import FilterModal from "../modals/filter-modal";

function FilterDimsTable(props) {
  let tabId = props.tabId || 3;
  let roleId = props.roleId || 0;
  let filterData = props.filters || [];
  let dimsData = props.dims || [];
  let shouldUpdateFilters = props.shouldUpdateFilters || false;
  let setShouldUpdateFilters = props.setShouldUpdateFilters || function () {};
  let setShouldUpdateDims = props.setShouldUpdateDims || function () {};
  let shouldUpdateDims = props.shouldUpdateDims || false;
  const userContext = useContext(UserContext);
  const [filters, setFilters] = useState(filterData);
  const [dims, setDims] = useState(dimsData);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [api_callable, setApi_callable] = useState("");
  const [currentFilter, setCurrentFilter] = useState({});
  const [currentDim, setCurrentDim] = useState({});
  const [loading, setLoading] = useState(undefined);

  useEffect(() => {
    if (tabId === 3)
      apiFunctions.Get(api_configuration.api_route.filters_api, setFilters);
    else apiFunctions.Get(api_configuration.api_route.dims_api, setDims);
  }, [tabId]);

  const [toggleSearch, setSearch] = useState(false);
  const local = Local;
  let Tstyle = props.tstyle || retrieveStyle(userContext.contrast);
  let icons = retrieveIcons(userContext.contrast);
  const opts = retrieveOpts(Tstyle, toggleSearch);
  const actionSearch = props.iconSearch || retrieveActionSearch(userContext);

  let acts;

  useEffect(() => {
    userContext.closeAccessibility();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading === false) {
      setShouldUpdateDims(!shouldUpdateDims);
      setShouldUpdateFilters(!shouldUpdateFilters);
    }
    // eslint-disable-next-line
  }, [loading]);

  window.onbeforeunload = function () {
    userContext.toggleAccessibility();
  };

  roleId === 5
    ? (acts = [
        {
          icon: "add",
          tooltip: tabId === 3 ? "Adicionar Filtro" : "Adicionar Dimensão",
          onClick: (event, elem) => {
            if (tabId === 3) {
              tableFunctions.createNewFilter(
                filters,
                setFilters,
                setShouldUpdateFilters,
                shouldUpdateFilters
              );
              setCurrentFilter(elem);
            } else {
              tableFunctions.createNewDimension(
                dims,
                setDims,
                setShouldUpdateDims,
                shouldUpdateDims
              );
              setCurrentDim(elem);
            }
            openOptionsModal(true);
          },
          isFreeAction: true,
        },
        {
          icon: actionSearch,
          tooltip: "busca",
          isFreeAction: true,
          onClick: (event) => setSearch(!toggleSearch),
        },
        {
          icon: "edit",
          tooltip: "Editar",
          onClick: (event, rowData) => {
            tableFunctions.setOpenOptionsModal(
              openOptionsModal,
              setOpenOptionsModal
            );
            if (tabId === 3) {
              setCurrentFilter(rowData);
            } else {
              setCurrentDim(rowData);
            }
          },
        },
      ])
    : (acts = [
        {
          icon: actionSearch,
          tooltip: "busca",
          isFreeAction: true,
          onClick: (event) => setSearch(!toggleSearch),
        },
      ]);

  useEffect(() => {
    setName(currentFilter.filter_name);
    setDescription(currentFilter.filter_description);
    setApi_callable(currentFilter.filter_api_callable);
    // eslint-disable-next-line
  }, [currentFilter]);

  useEffect(() => {
    setName(currentDim.dimension_name);
    setDescription(currentDim.dimension_description);
    setApi_callable(currentDim.dimension_api_callable);
    // eslint-disable-next-line
  }, [currentDim]);

  let header_list;
  let title;
  if (tabId === 3) {
    title = "Filtros";
    header_list = [
      { field: "filter_name", title: "Nome" },
      { field: "filter_description", title: "Descrição" },
      {
        field: "filter_api_callable",
        title: "Rota do Filtro",
      },
    ];
  }
  if (tabId === 4) {
    title = "Dimensões";
    header_list = [
      { field: "dimension_name", title: "Nome" },
      { field: "dimension_description", title: "Descrição" },
      { field: "dimension_api_callable", title: "Rota da Dimensão" },
    ];
  }

  let modalContent = (
    <>
      <Input
        inputPlaceholder={"Nome"}
        labelText={tabId === 3 ? "Nome do Filtro" : "Nome da Dimensão"}
        inputValue={name}
        labelFor={"name"}
        inputId={"name"}
        handleChange={(e) => setName(e.target.value)}
        onBlur={() => {}}
      />
      <div style={{ height: "1rem" }} />
      <Input
        inputPlaceholder={"Código"}
        labelText={tabId === 3 ? "Código do Filtro" : "Código da Dimensão"}
        inputValue={description}
        labelFor={"description"}
        inputId={"description"}
        handleChange={(e) => setDescription(e.target.value)}
        onBlur={() => {}}
      />
      <div style={{ height: "1rem" }} />
      <Input
        inputPlaceholder={"Rota"}
        labelText={tabId === 3 ? "Rota do Filtro" : "Rota da Dimensão"}
        inputValue={api_callable}
        inputId={"api_callable"}
        labelFor={"api_callable"}
        handleChange={(e) => setApi_callable(e.target.value)}
        onBlur={() => {}}
      />
    </>
  );

  function onSave() {
    if (tabId === 3) {
      let body = {
        filter_name: name,
        filter_description: description,
        filter_api_callable: api_callable,
      };
      apiFunctions.Patch(
        api_configuration.api_route.filters_api + currentFilter.id,
        body,
        undefined,
        setLoading
      );
    } else {
      let body = {
        dimension_name: name,
        dimension_description: description,
        dimension_api_callable: api_callable,
      };
      apiFunctions.Patch(
        api_configuration.api_route.dims_api + currentDim.id,
        body,
        undefined,
        setLoading
      );
    }
    setOpenOptionsModal(!openOptionsModal);
  }

  return (
    <div className={`user-container ${userContext.contrast}`}>
      <FilterModal
        open={openOptionsModal}
        onSave={() => {
          onSave();
        }}
        onHandle={() => {
          setOpenOptionsModal(!openOptionsModal);
        }}
        label={"Salvar"}
        icon={"none"}
        description={modalContent}
      />
      <MaterialTable
        title={title}
        columns={header_list}
        data={tabId === 3 ? filters : dims}
        actions={acts}
        options={opts}
        localization={local}
        editable={false}
        style={Tstyle}
        icons={icons}
      />
    </div>
  );
}

export default FilterDimsTable;
