/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from "react";
import Backdrop from "../backdrop/backdrop";
import Modal from "./modal";
import DsGovModal from "../../ds-gov/Modal";
import Input from "../../ds-gov/Input";
import {
  handleShowPassword,
  handleFieldChange,
  validateConfirmPassword,
  isFieldValid,
  isFieldInvalid,
  validatePassword,
  validateNameField,
  handleCpfValidation,
  handleEmailValidation,
  handleValidateBirthDateField,
} from "./utils/validation";
import NotificationContext from "../../../Store/notification-store";
import "./login-modal.css";
import Button from "../../ds-gov/Button";
import UserContext from "../../../Store/user-context";
import api_configuration from "../../../api_configuration";
import * as apiFunctions from "../../../Store/apiFunctions";
/* Parameters
 * open: boolean
 * onHandle: function */

function SignupModal(props) {
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);

  let open = props.open || false;
  let onHandle = props.onHandle || function () {};

  const [fullNameField, setFullNameField] = useState("");
  const [isFullNameValid, setIsFullNameValid] = useState(undefined);

  const [cpfField, setCpfField] = useState("");
  const [isCpfValid, setIsCpfValid] = useState(undefined);
  const [cpfError, setCpfError] = useState(undefined);

  const [birthDateField, setBirthDateField] = useState("");
  const [isBirthDateValid, setIsBirthDateValid] = useState(undefined);

  const [emailField, setEmailField] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);

  const [workPlaceField, setWorkPlaceField] = useState("");

  const [occupationField, setOccupationField] = useState("");

  const [passwordField, setPasswordField] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(undefined);

  const [confirmPasswordField, setConfirmPasswordField] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    useState(undefined);
  const [signupTry, setSignUpTry] = useState(false);

  useEffect(() => {
    if (fullNameField.length > 0)
      validateNameField(setIsFullNameValid, fullNameField);
    else setIsFullNameValid(undefined);
  }, [fullNameField]);

  useEffect(() => {
    setCpfError(undefined);
    if (cpfField.length > 0) handleCpfValidation(setIsCpfValid, cpfField);
    else setIsCpfValid(undefined);
  }, [cpfField]);

  useEffect(() => {
    if (birthDateField.length > 0) {
      handleValidateBirthDateField(setIsBirthDateValid, birthDateField);
    } else setIsBirthDateValid(undefined);
  }, [birthDateField]);

  useEffect(() => {
    setEmailError(undefined);
    if (fullNameField.length > 0)
      handleEmailValidation(setIsEmailValid, emailField);
    else setIsEmailValid(undefined);
    // eslint-disable-next-line
  }, [emailField]);

  useEffect(() => {
    if (passwordField.length > 0) {
      validatePassword(setIsPasswordValid, passwordField);
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPasswordField,
        passwordField
      );
    } else setIsPasswordValid(undefined);
    // eslint-disable-next-line
  }, [passwordField]);

  useEffect(() => {
    if (confirmPasswordField.length > 0) {
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPasswordField,
        passwordField
      );
    } else setIsConfirmPasswordValid(undefined);
    // eslint-disable-next-line
  }, [confirmPasswordField]);

  const resetFields = () => {
    setFullNameField("");
    setIsFullNameValid(undefined);
    setCpfField("");
    setIsCpfValid(undefined);
    setCpfError(undefined);
    setBirthDateField("");
    setIsBirthDateValid(undefined);
    setEmailField("");
    setIsEmailValid(undefined);
    setEmailError(undefined);
    setWorkPlaceField("");
    setOccupationField("");
    setPasswordField("");
    setShowPassword(false);
    setIsPasswordValid(undefined);
    setConfirmPasswordField("");
    setShowConfirmPassword(false);
    setIsConfirmPasswordValid(undefined);
  };

  const onCancelModal = () => {
    resetFields();
    onHandle();
  };

  const handleSignupError = (message) => {
    notificationContext.showNotification({
      title: "Erro!",
      message: "Verifique os campos novamente",
      status: "error",
      position: "top-right",
    });
    if (message === "email") setEmailError(true);
    if (message === "cpf") setCpfError(true);
  };

  const onSignupChange = () => {
    setSignUpTry(!signupTry);
    let user = {
      name: fullNameField,
      email: emailField,
      cpf: cpfField,
      password: passwordField,
      birth_date: birthDateField,
      job_place: workPlaceField,
      job_position: occupationField,
    };
    apiFunctions.PostUnauth(
      api_configuration.api_route.signup_api,
      user,
      () => {
        onHandle();
        notificationContext.showNotification({
          title: "Sucesso",
          message: "Cadastro concluído com sucesso!",
          status: "success",
          position: "top-right",
        });
      },
      handleSignupError
    );
  };

  let title = (
    <span className={`${userContext.contrast}Title`}>Preencha seus dados</span>
  );

  let cancelButton = (
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      label="Cancelar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={onCancelModal}
    />
  );

  let signUpButton = (
    <Button
      bground="primary"
      pstate={
        isFieldValid(isFullNameValid) &&
        isFieldValid(isCpfValid) &&
        isFieldValid(isBirthDateValid) &&
        isFieldValid(isEmailValid) &&
        isFieldValid(isPasswordValid) &&
        isFieldValid(isConfirmPasswordValid)
          ? ""
          : "disabled"
      }
      psize="small"
      label="Cadastrar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      icon="fas fa-user-plus"
      onclick={onSignupChange}
    />
  );

  let footer = (
    <>
      {cancelButton}
      {signUpButton}
    </>
  );
  let body = (
    <>
      <Input
        brInputClassName={
          isFieldValid(isFullNameValid)
            ? `br-input success input-${userContext.contrast}`
            : isFieldInvalid(isFullNameValid)
            ? `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isFullNameValid) || isFieldInvalid(isFullNameValid)
        }
        messageText={
          isFieldValid(isFullNameValid)
            ? "O nome é válido"
            : "O nome é inválido, precisa ter pelo menos um caractere e não pode ter números"
        }
        messageIcon={
          isFieldValid(isFullNameValid)
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          isFieldValid(isFullNameValid)
            ? "success"
            : isFieldInvalid(isFullNameValid)
            ? "danger"
            : ""
        }
        labelText="Nome completo"
        inputType="text"
        inputValue={fullNameField}
        inputPlaceholder="Digite seu nome"
        handleChange={(e) => handleFieldChange(e, setFullNameField)}
        onBlur={() => {}}
        labelFor="Name"
        inputId="Name"
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          cpfError
            ? `br-input danger input-${userContext.contrast}`
            : isFieldValid(isCpfValid)
            ? `br-input success input-${userContext.contrast}`
            : isFieldInvalid(isCpfValid)
            ? `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isCpfValid) ||
          cpfError === false ||
          isFieldInvalid(isCpfValid)
        }
        messageText={
          cpfError
            ? "Já existe uma conta com esse CPF"
            : isFieldValid(isCpfValid)
            ? "O CPF é válido"
            : "O CPF é inválido"
        }
        messageIcon={
          isFieldValid(isCpfValid) && !cpfError
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          cpfError
            ? "danger"
            : isFieldValid(isCpfValid)
            ? "success"
            : isFieldInvalid(isCpfValid)
            ? "danger"
            : ""
        }
        labelText="CPF"
        inputType="text"
        inputValue={cpfField}
        inputPlaceholder="Digite seu CPF"
        handleChange={(e) => handleFieldChange(e, setCpfField)}
        onBlur={() => {}}
        labelFor="CPF"
        inputId="CPF"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          isFieldValid(isBirthDateValid)
            ? `br-input success input-${userContext.contrast}`
            : isFieldInvalid(isBirthDateValid)
            ? `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isBirthDateValid) || isFieldInvalid(isBirthDateValid)
        }
        messageText={
          isFieldValid(isBirthDateValid)
            ? "A data é válida"
            : "A data é inválida, verifique se a data inserida é maior do que hoje ou se está fora do padrão exigido"
        }
        messageIcon={
          isFieldValid(isBirthDateValid)
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          isFieldValid(isBirthDateValid)
            ? "success"
            : isFieldInvalid(isBirthDateValid)
            ? "danger"
            : ""
        }
        labelText="Data de nascimento"
        inputType="date"
        inputValue={birthDateField}
        inputPlaceholder="dd/mm/yyyy"
        handleChange={(e) => handleFieldChange(e, setBirthDateField)}
        onBlur={() => {}}
        labelFor="birth-date"
        inputId="birth-date"
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação e repare que o padrão exigido é dia/mês/ano"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          emailError
            ? `br-input danger input-${userContext.contrast}`
            : isFieldValid(isEmailValid)
            ? `br-input success input-${userContext.contrast}`
            : isFieldInvalid(isEmailValid)
            ? `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          emailError === false ||
          isFieldValid(isEmailValid) ||
          isFieldInvalid(isEmailValid)
        }
        messageText={
          emailError
            ? "Já existe uma conta com esse email"
            : isFieldValid(isEmailValid)
            ? "O email é válido"
            : "O email é inválido"
        }
        messageIcon={
          isFieldValid(isEmailValid) && !emailError
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          emailError
            ? "danger"
            : isFieldValid(isEmailValid)
            ? "success"
            : isFieldInvalid(isEmailValid)
            ? "danger"
            : ""
        }
        labelText="Email"
        inputType="email"
        inputValue={emailField}
        inputPlaceholder="Digite seu email"
        handleChange={(e) => handleFieldChange(e, setEmailField)}
        onBlur={() => {}}
        labelFor="email"
        inputId="email"
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={`br-input input-${userContext.contrast}`}
        labelText="Local de trabalho (não obrigatório)"
        inputType="text"
        inputValue={workPlaceField}
        inputPlaceholder="Digite seu local de trabalho"
        handleChange={(e) => handleFieldChange(e, setWorkPlaceField)}
        onBlur={() => {}}
        labelFor="address"
        inputId="address"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={`br-input input-${userContext.contrast}`}
        labelText="Função (não obrigatório)"
        inputType="text"
        inputValue={occupationField}
        inputPlaceholder="Digite a sua função"
        handleChange={(e) => handleFieldChange(e, setOccupationField)}
        onBlur={() => {}}
        labelFor="occupation"
        inputId="occupation"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          isFieldValid(isPasswordValid)
            ? `br-input success input-${userContext.contrast}`
            : isFieldInvalid(isPasswordValid)
            ? `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isPasswordValid) || isFieldInvalid(isPasswordValid)
        }
        messageText={
          isFieldValid(isPasswordValid)
            ? "A senha é válida"
            : "A senha é inválida, precisa ter 8 caracteres ou mais"
        }
        messageIcon={
          isFieldValid(isPasswordValid)
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          isFieldValid(isPasswordValid)
            ? "success"
            : isFieldInvalid(isPasswordValid)
            ? "danger"
            : ""
        }
        labelText="Senha"
        inputType={showPassword ? "text" : "password"}
        inputValue={passwordField}
        inputPlaceholder="Digite sua senha"
        handleChange={(e) => handleFieldChange(e, setPasswordField)}
        onBlur={() => {}}
        labelFor="input-password"
        inputId="input-password"
        hasButton={true}
        buttonClassName="br-button circle small"
        buttonType="button"
        buttonArialLabel="Mostrar senha"
        buttonIcon={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
        handleClick={() => handleShowPassword(setShowPassword)}
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          passwordField.length > 0
            ? isFieldValid(isPasswordValid)
              ? isFieldValid(isConfirmPasswordValid)
                ? `br-input success input-${userContext.contrast}`
                : ` br-input danger input-${userContext.contrast}`
              : ` br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isConfirmPasswordValid) ||
          isFieldInvalid(isConfirmPasswordValid)
        }
        messageText={
          isFieldValid(isPasswordValid)
            ? isFieldValid(isConfirmPasswordValid)
              ? "As senhas são iguais"
              : "As senhas não são iguais"
            : "A senha é inválida, precisa ter 8 caracteres ou mais"
        }
        messageIcon={
          isFieldValid(isPasswordValid)
            ? isFieldValid(isConfirmPasswordValid)
              ? "fas fa-check-circle"
              : "fas fa-times-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          isFieldValid(isPasswordValid)
            ? isFieldValid(isConfirmPasswordValid)
              ? "success"
              : "danger"
            : "danger"
        }
        labelText="Confirme sua senha"
        inputType={showConfirmPassword ? "text" : "password"}
        inputValue={confirmPasswordField}
        inputPlaceholder="Digite sua senha"
        handleChange={(e) => handleFieldChange(e, setConfirmPasswordField)}
        onBlur={() => {}}
        labelFor="input-confirm-password"
        inputId="input-confirm-password"
        hasButton={true}
        buttonClassName="br-button circle small"
        buttonType="button"
        buttonArialLabel="Mostrar senha"
        buttonIcon={showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"}
        handleClick={() => handleShowPassword(setShowConfirmPassword)}
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
    </>
  );

  return (
    <>
      {open && <Backdrop onClick={onCancelModal} />}
      {open && (
        <Modal>
          <DsGovModal
            title={title}
            justifyFooter="end"
            body={body}
            footer={footer}
            onCancelModal={onCancelModal}
          />
        </Modal>
      )}
    </>
  );
}

export default SignupModal;
