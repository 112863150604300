/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/


function Button(props) {
    let bground = props.bground ? props.bground : '' //primary or secondary
    let pstate = props.pstate ? props.pstate : '' //disabled or nothing
    let psize = props.psize ? props.psize : '' // large or small
    let label = props.label ? props.label : '' //texto que vai no botão pode ser vazio
    let inverted = props.inverted ? props.inverted : '' //se inverte ou não a cor
    let circle = props.circle ? props.circle : '' //se o botão é um circulo ou não
    let altstate = props.altstate ? props.altstate : '' // se o botão é ativo ou loading, opcional
    let block = props.block ? props.block : '' // block or nothing
    let icon = props.icon ? props.icon : '' // icone a ser usado, texto, adicionar <i className="fas fa-city mr-1" aria-hidden="true"></i> quando houverem icones. opcional
    let margin = props.margin ? props.margin : "m-2"
    let onclick = props.onclick ? props.onclick : () => { }
    let tooltip = props.tooltip ? props.tooltip : ''
    let datatoggle = props.datatoggle ? props.datatoggle : ''
    let datatarget = props.datatarget ? props.datatarget : ''
    // let border = props.border ? props.border : 0

    let class_content = "br-button " + bground + ' ' + psize + ' ' + inverted + ' ' + circle + ' ' + altstate + ' ' + block + margin;

    if (icon) {
        return (
            <button className={class_content} data-toggle={datatoggle} data-target={datatarget} title={tooltip} onClick={onclick} type="button" disabled={pstate}><i className={icon} aria-hidden="true"></i>{label}
            </button>
        )
    }
    else {
        return (
            <button className={class_content} title={tooltip} onClick={onclick} type="button" disabled={pstate}>{label}
            </button>
        )
    }


}

export default Button;