/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from 'react';
import Backdrop from '../backdrop/backdrop';
import Modal from './modal';
import DsGovModal from '../../ds-gov/Modal';
import Input from '../../ds-gov/Input';
import * as apiFunctinos from "../../../Store/apiFunctions"
import {
  handleFieldChange,
  isFieldValid,
  handleEmailValidation,
} from './utils/validation';
import "./login-modal.css";
import Button from '../../ds-gov/Button';
import UserContext from '../../../Store/user-context';
import api_configuration from '../../../api_configuration';

/* Parameters 
 * open: boolean 
 * onHandle: function 
 * onGoBackForgotPassModal: function */

function ForgotPassModal(props) {
  const userContext = useContext(UserContext);

  let open = props.open || false;
  let onHandle = props.onHandle || function () { };
  let onGoBackForgotPassModal = props.onGoBackForgotPassModal || function () { };


  const [email, setEmail] = useState("");
  const [isEdittingEmail, setEdittingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(undefined);

  useEffect(() => {
    handleEmailValidation(setIsEmailValid, email);
  }, [email]);

  const resetFields = () => {
    setEmail("");
    setIsEmailValid(undefined);
  }

  const handleGoBack = () => {
    onGoBackForgotPassModal();
    resetFields();
  }

  const onCancelModal = () => {
    resetFields();
    onHandle();
  }

  let title = <span className={`${userContext.contrast}Title`}>
    Preencha os campos para a recuperação de senha
  </span>

  let goBackButton =
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      icon="fas fa-arrow-left"
      label="Voltar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={handleGoBack}
    />

  let sendEmailButton =
    <Button
      bground="primary"
      pstate={
          isFieldValid(isEmailValid)
          ? "" : "disabled"
      }
      psize="small"
      label="Enviar email"
      inverted={userContext.contrast === "contrast" && "inverted"}
      icon="fas fa-paper-plane"
      onclick={() => {apiFunctinos.PostUnauth(api_configuration.api_route.reset_tickets_api, {email: email}, onCancelModal)}}
    />

  let footer =
    <>
      {goBackButton}
      {sendEmailButton}
    </>
  let body =
    <>
      <Input
        brInputClassName={
          isEdittingEmail
            ? isEmailValid
              ? `br-input success input-${userContext.contrast}`
              : ` br-input danger input-${userContext.contrast} `
            : `br-input input-${userContext.contrast}`
        }
        showMessage={isEdittingEmail ? isEmailValid || !isEmailValid : false}
        messageText={isEmailValid ? "Email válido" : "Email inválido"}
        messageIcon={
          isEmailValid ? "fas fa-check-circle" : "fas fa-times-circle"
        }
        feedBackType={isEmailValid ? "success" : !isEmailValid ? "danger" : ""}
        labelText="Email"
        type="text"
        inputValue={email}
        inputPlaceholder={"Digite o seu email"}
        handleChange={(e) => {
          handleFieldChange(e, setEmail);
          setEdittingEmail(true);
        }}
        onBlur={() => handleEmailValidation(setIsEmailValid, email)}
        labelFor="input-email"
        inputId="input-email"
      />
    </>


  return (
    <>
      {open && <Backdrop onClick={onCancelModal} />}
      {open && (
        <Modal>
          <DsGovModal
            title={title}
            justifyFooter="end"
            body={body}
            footer={footer}
            onCancelModal={onCancelModal}
          />
        </Modal>
      )}
    </>
  )
}

export default ForgotPassModal
