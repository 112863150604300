import React from "react";
import Error404 from "../Components/app/error/error404";
function Error404Page() {
  return (
    <>
      <Error404 />
    </>
  );
}

export default Error404Page;
