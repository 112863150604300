import React, { memo } from "react";
import DashboardCard from "../personalized-dashboard/dashboard-card";
import { useLocation } from "react-router-dom";

function FullDashboardPersonalizedComponent() {
  const location = useLocation();

  if (location.state) {
    return (
      <DashboardCard
        id={location.state.id}
        fullPage={true}
        availableIndicators={location.state.availableIndicators}
        savedFilter={location.state.savedFilter}
        savedKeys={location.state.savedKeys}
      />
    );
  } else {
    return <div> erro </div>;
  }
}

export default memo(FullDashboardPersonalizedComponent);
