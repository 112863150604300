import React from "react";
import Error from "../Components/app/error/error";
function ErrorPage() {
  return (
    <>
      <Error />
    </>
  );
}

export default ErrorPage;
