/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRCard from './js-dsgov/Card'
import "./Styles/Card.css"
//NOTE: aterado função  _setDisableBehavior()

function Card(props) {

  /*
    **Parameters**
      - title: string
      - cardType: 'simple', 'complete', 'flip' e 'modal' (default simple)
      - content: content of cart (card front if it is cardType flip)
      - verseContent: content of verse if cardType flip
      - disabled: 'disabled' or ''
      - headerContent: content of header
      - footerContent: content of footer
      - cardContrast: 'contrast' if it is in contrast mode (default '')
  */

  let content = props.content ? props.content : ''
  let verseContent = props.verseContent ? props.verseContent : ''
  let cardType = props.cardType ? props.cardType : 'simple'
  let title = props.title ? props.title : ''
  let disabled = props.disabled ? props.disabled : ''

  /*CARD HEADER*/
  let headerContent = props.headerContent ? props.headerContent : ''

  /*FLIP CARD*/
  let flipButton = props.flipButton ? props.flipButton : <button className="br-button circle" type="button" aria-label="Girar o card" data-toggle="flip"><i className="fas fa-exchange-alt" aria-hidden="true"></i>
  </button>
  let flipBackButton = props.flipBackButton ? props.flipBackButton : <button className="br-button circle inverted" type="button" aria-label="Girar o card" data-toggle="flip"><i className="fas fa-exchange-alt" aria-hidden="true"></i>
  </button>
  let flipTitle = props.flipTitle ? props.flipTitle : {}
  let flip_ids = props.flip_ids ? props.flip_ids : ['front', 'back']
  /*CARD FOOTER*/
  let footerContent = props.footerContent ? props.footerContent : ''

  /*CARD PROPERTIES */
  let cardHeight = props.cardHeight ? props.cardHeight : ''
  let cardWidth = props.cardWidth ? props.cardWidth : ''
  let cardVisibility = props.cardVisibility ? props.cardVisibility : ''
  let cardContrast = props.cardContrast ? 'card' + props.cardContrast : ''


  const cardStyle = {
    'height': cardHeight,
    'width': cardWidth,
    'visibility': cardVisibility,
  }


  const listCard = []
  for (const [index, brCard] of window.document
    .querySelectorAll('.br-card')
    .entries()) {
    listCard.push(new BRCard('br-card', brCard, index))
  }

  let div_footer = ''
  if (footerContent !== '') {
    div_footer =
      <div className="d-flex">
        {footerContent}
      </div>
  }

  switch (cardType) {
    case 'complete':
      return (
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div className="col-12">
              <p className="h5">{title}</p>
              <div className={`card ${disabled}`} style={cardStyle}>
                <div className={`header ${cardContrast}`} >
                  {headerContent}
                </div>
                <div className={`card-content ${cardContrast}`}>
                  {content}
                </div>
                <div className={`card-footer ${cardContrast}`}>
                  {div_footer}
                </div>
              </div>
            </div>
          </div>
        </div>
      )

    case 'flip':
      return (
        <div className="col-12">
          <p className="h5">{title}</p>
          <div className={`br-card ${disabled}`} data-flip="data-flip" style={cardStyle}>
            <div className={`front ${cardContrast}`} id={flip_ids[0]}>
              <div className="header">
                <div className="d-flex">
                  <p className="h5">{flipTitle.front}</p>
                  <div className="ml-auto">
                    {flipButton}
                  </div>
                </div>
              </div>
              <div className={`card-content`}>
                {content}
              </div>
            </div>
            <div className="back" id={flip_ids[1]}>
              <div className={`header ${cardContrast}`}>
                <div className="d-flex">
                  <div>
                    <p className="h5">{flipTitle.back}</p>
                  </div>
                  <div className="ml-auto">
                    {flipBackButton}
                  </div>
                </div>
              </div>
              <div className={`card-content ${cardContrast}`}>
                {verseContent}
              </div>
            </div>
          </div>
        </div>
      )

    case 'modal':
      return (
        <div className="container-fluid">
          <div className="row">
            <div>
              <div className={`br-card ${disabled}`} style={cardStyle}>
                <div className={`${cardContrast}`}>
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      )

    default:
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p className="h5">{title}</p>
              <div className={`br-card ${disabled}`} style={cardStyle} >
                <div className="card-content">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      )

  }
}

export default Card;
