/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext } from 'react';
import Backdrop from '../backdrop/backdrop';
import Modal from './modal';
import DsGovModal from '../../ds-gov/Modal';
import Input from '../../ds-gov/Input';
import {
  handleShowPassword,
  handleFieldChange,
  validateConfirmPassword,
  isFieldValid,
  isFieldInvalid,
  validatePassword,
} from './utils/validation';
import "./login-modal.css";
import Button from '../../ds-gov/Button';
import UserContext from '../../../Store/user-context';

/* Parameters 
 * open: boolean 
 * onHandle: function */

function ResetPassModal(props) {
  const userContext = useContext(UserContext);

  let open = props.open || false;
  let onHandle = props.onHandle || function () { };

  const [passwordField, setPasswordField] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(undefined);

  const [confirmPasswordField, setConfirmPasswordField] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(undefined);

  const resetFields = () => {
    setPasswordField("");
    setShowPassword(false);
    setIsPasswordValid(undefined);

    setConfirmPasswordField("");
    setShowConfirmPassword(false);
    setIsConfirmPasswordValid(undefined);
  }

  const onCancelModal = () => {
    resetFields();
    onHandle();
  }

  let title = <span className={`${userContext.contrast}Title`}>
    Insira sua nova senha
  </span>

  let cancelButton =
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      label="Cancelar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={onCancelModal}
    />

  let signInButton =
    <Button
      bground="primary"
      pstate={
        isFieldValid(isPasswordValid) &&
          isFieldValid(isConfirmPasswordValid)
          ? "" : "disabled"
      }
      psize="small"
      label="Recadastrar senha"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={onCancelModal}
    />

  let footer =
    <>
      {cancelButton}
      {signInButton}
    </>
  let body =
    <>
      <Input
        brInputClassName={
          isFieldValid(isPasswordValid) ? `br-input success ${userContext.contrast}` :
            isFieldInvalid(isPasswordValid) ? `br-input danger ${userContext.contrast}` : `br-input ${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isPasswordValid) || isFieldInvalid(isPasswordValid)
        }
        messageText={
          isFieldValid(isPasswordValid) ? "A senha é válida" : "A senha é inválida, precisa ter 8 caracteres ou mais"}
        messageIcon={isFieldValid(isPasswordValid) ? "fas fa-check-circle" : "fas fa-times-circle"}
        feedBackType={
          isFieldValid(isPasswordValid) ? "success" :
            isFieldInvalid(isPasswordValid) ? "danger" : ""}
        labelText="Senha"
        inputType={showPassword ? "text" : "password"}
        inputValue={passwordField}
        inputPlaceholder="Digite sua senha"
        handleChange={(e) => handleFieldChange(e, setPasswordField)}
        onBlur={() => validatePassword(setIsPasswordValid, passwordField)}
        labelFor="input-password"
        inputId="input-password"
        hasButton={true}
        buttonClassName="br-button circle small"
        buttonType="button"
        buttonArialLabel="Mostrar senha"
        buttonIcon={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
        handleClick={() => handleShowPassword(setShowPassword)}
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
      <div className='sized-box__form' />
      <Input
        brInputClassName={
          isFieldValid(isConfirmPasswordValid) ? `br-input success ${userContext.contrast}` :
            isFieldInvalid(isConfirmPasswordValid) ? `br-input danger ${userContext.contrast}` : `br-input ${userContext.contrast}`
        }
        showMessage={
          isFieldValid(isConfirmPasswordValid) || isFieldInvalid(isConfirmPasswordValid)
        }
        messageText={
          isFieldValid(isConfirmPasswordValid) ? "As senhas são iguais" : "As senhas não são iguais"}
        messageIcon={isFieldValid(isConfirmPasswordValid) ? "fas fa-check-circle" : "fas fa-times-circle"}
        feedBackType={
          isFieldValid(isConfirmPasswordValid) ? "success" :
            isFieldInvalid(isConfirmPasswordValid) ? "danger" : ""}
        labelText="Confirme sua senha"
        inputType={showConfirmPassword ? "text" : "password"}
        inputValue={confirmPasswordField}
        inputPlaceholder="Digite sua senha"
        handleChange={(e) => handleFieldChange(e, setConfirmPasswordField)}
        onBlur={() => validateConfirmPassword(setIsConfirmPasswordValid, confirmPasswordField, passwordField)}
        labelFor="input-confirm-password"
        inputId="input-confirm-password"
        hasButton={true}
        buttonClassName="br-button circle small"
        buttonType="button"
        buttonArialLabel="Mostrar senha"
        buttonIcon={showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"}
        handleClick={() => handleShowPassword(setShowConfirmPassword)}
        hasToolTip={true}
        toolTipText="Retire o foco do campo de texto para a validação"
      />
    </>


  return (
    <>
      {open && <Backdrop onClick={onCancelModal} />}
      {open && (
        <Modal>
          <DsGovModal
            title={title}
            justifyFooter="end"
            body={body}
            footer={footer}
            onCancelModal={onCancelModal}
          />
        </Modal>
      )}
    </>
  )
}

export default ResetPassModal
