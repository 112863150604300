/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRTab from "./js-dsgov/Tab"; // Na linha 151 do arquivo, na função clean, adicionado parametro event para evitar erro que event não tem referencia
import React, { useEffect } from "react";

function Tab(props) {
  useEffect(() => {
    const abasList = [];
    for (const brTab of window.document.querySelectorAll(".br-tab")) {
      abasList.push(new BRTab("br-tab", brTab));
    }
  }, []);

  let content = props.content
    ? props.content
    : [
        {
          aria_label: "panel-1",
          label: "label padrão",
          sub_label: "label padrão",
          active: "is-active",
        },
      ]; //adicionar count se for tab com count, imagem se for tab com imagem
  let count = props.count ? props.count : false;
  let count_extra = props.count_extra
    ? props.count_extra
    : {
        label: "default count label",
        sub_label: "sublable de count",
        total: "total",
      };
  let image = props.image ? props.image : false;
  let images = props.images ? props.images : [];
  let inverted = props.inverted ? props.inverted : "";

  let handleChange = props.handleChange ? props.handleChange : function () {}; //funtion **

  if (count) {
    return (
      <div className={"br-tab " + inverted} data-counter="true">
        <nav className="tab-nav" aria-label="Abas de Navegação Simples">
          <ul>
            <li className="tab-item" not-tab="true">
              <span className="name">{count_extra.label}</span>
              <p className="outcome">
                {count_extra.sub_label}
                <span>{count_extra.total}</span>
              </p>
            </li>
            {content.map((content, index) => (
              <li
                key={"content3_" + content + index}
                className={"tab-item " + content.active}
              >
                <button
                  type="button"
                  onClick={(event) => handleChange(index)}
                  data-panel={content.aria_label}
                  aria-label={content.aria_label}
                >
                  <span className="name">{content.label}</span>
                </button>
                <span className="results">{"( " + content.count + ")"} </span>
              </li>
            ))}
          </ul>
        </nav>
        <div className="tab-content">
          {content.map((content) => (
            <div
              className={"tab-panel " + content.active}
              id={content.aria_label}
            >
              <p>{content.sub_label}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (image) {
    return (
      <div className={"br-tab " + inverted}>
        <nav className="tab-nav" aria-label="Abas de Navegação Simples">
          <ul>
            {React.Children.toArray(content.map((content, index) => (
              <li
                key={"content_2_" + content + index}
                className={"tab-item " + content.active}
              >
                <button
                  type="button"
                  onClick={(event) => handleChange(index)}
                  data-panel={content.aria_label}
                  aria-label={content.aria_label}
                >
                  <span className="name">
                    <span className="row align-items-center">
                      <span className="col d-flex justify-content-center">
                        {images[index]}{" "}
                      </span>
                      <span className="col">{content.label} </span>
                    </span>
                  </span>
                </button>
              </li>
            )))}
          </ul>
        </nav>
        <div className="tab-content">
          {React.Children.toArray(content.map((content) => (
            <div
              className={"tab-panel " + content.active}
              id={content.aria_label}
            >
              <p>{content.sub_label}</p>
            </div>
          )))}
        </div>
      </div>
    );
  }

  return (
    <div className={"br-tab " + inverted}>
      <nav className="tab-nav" aria-label="Abas de Navegação Simples">
        <ul>
          {React.Children.toArray(content.map((content, index) => (
            <li
              className={"tab-item " + content.active}
            >
              <button
                type="button"
                onClick={(event) => handleChange(index)}
                data-panel={content.aria_label}
                aria-label={content.aria_label}
              >
                <span className="name">{content.label}</span>
              </button>
            </li>
          )))}
        </ul>
      </nav>
      <div className="tab-content">
        {React.Children.toArray(content.map((content) => (
          <div
            className={"tab-panel " + content.active}
            id={content.aria_label}
          >
            <p>{content.sub_label}</p>
          </div>
        )))}
      </div>
    </div>
  );
}

export default Tab;
