/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from "react";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import UserContext from "../../../Store/user-context";
import {
  retrieveStyle,
  retrieveIcons,
  Local,
  retrieveActionSearch,
  retrieveOpts,
} from "./configuration";
import * as tableFunctions from "./tableManagementFunctions";
import * as apiFunctions from "../../../Store/apiFunctions";
import FilterModal from "../modals/filter-modal";
import "./management.css";
import List from "../../ds-gov/List";
import api_configuration from "../../../api_configuration";
import Input from "../../ds-gov/Input";
import CustomSelectFilters from "../../../helpers/CustomSelectFilters";

function GroupsTable(props) {
  let indicatorsData = props.indicatorsData || [];
  let groupsData = props.data;
  let users_group = props.users;
  let shouldUpdate = props.shouldUpdateData || false;
  let setShouldUpdate = props.setShouldUpdateData || function () {};
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(undefined);
  const [groupName, setGroupName] = useState();
  const [groupDescription, setGroupDescription] = useState();
  const [groupCode, setGroupCode] = useState();
  const [data, setData] = useState([]);
  const [openRemoveGroup, setOpenRemoveGroup] = useState(false);
  const [indicatorsOptions, setIndicatorsOptions] = useState([]);
  const [indicatorsChecked, setIndicatorsChecked] = useState([]);
  const [removeIndicatorsOptions, setRemoveIndicatorsOptions] = useState([]);
  const [removeIndicatorsChecked, setRemoveIndicatorsChecked] = useState([]);
  const [groupIndicators, setGroupIndicators] = useState();
  const [usersGroup, setUsersGroup] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [usersChecked, setUsersChecked] = useState([]);
  const [removeUsersOptions, setRemoveUsersOptions] = useState([]);
  const [removeUsersChecked, setRemoveUsersChecked] = useState([]);
  const [usersGroupData, setUsersGroupData] = useState(users_group);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [usersListData, setUsersListData] = useState({
    list: [],
  });
  const [currentGroup, setCurrentGroup] = useState({
    indicators: [],
  });
  const [toggleSearch, setSearch] = useState(false);
  const local = Local;
  let Tstyle = props.tstyle || retrieveStyle(userContext.contrast);
  let icons = retrieveIcons(userContext.contrast);
  const opts = retrieveOpts(Tstyle, toggleSearch);
  const actionSearch = props.iconSearch || retrieveActionSearch(userContext);

  /* add indicator property to all groups to prevent undefined errors */
  function treatGroupsData(groupsData) {
    for (let i = 0; i < groupsData.length; i++) {
      let group = groupsData[i];
      if (!("indicators" in group)) {
        group.indicators = [];
      }
    }
    setData(groupsData);
  }

  useEffect(() => {
    userContext.closeAccessibility();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading === false) {
      setShouldUpdate(!shouldUpdate);
    }
    // eslint-disable-next-line
  }, [loading]);

  window.onbeforeunload = function () {
    userContext.toggleAccessibility();
  };

  function getIndicatorById(id) {
    for (let i = 0; i < indicatorsData.length; i++) {
      const indicator = indicatorsData[i];
      if (indicator.id === id) return indicator;
    }
  }

  useEffect(() => {
    treatGroupsData(groupsData);
    tableFunctions.treatUsersGroupData(usersGroupData, setUsersGroupData);
    // eslint-disable-next-line
  }, []);

  let acts = [];
  acts = [
    {
      icon: "edit",
      tooltip: "Editar Grupo",
      onClick: (event, group) => {
        tableFunctions.viewGroupUsers(
          setCurrentGroup,
          group,
          setOpenOptionsModal,
          setUsersGroup,
          setGroupIndicators,
          setRemoveIndicatorsOptions
        );
      },
    },
    {
      icon: "add",
      tooltip: "Adicionar Grupo",
      isFreeAction: true,
      onClick: (event, group) => tableFunctions.createNewGroup(data, setData),
    },
    {
      icon: actionSearch,
      tooltip: "busca",
      isFreeAction: true,
      onClick: (event) => setSearch(!toggleSearch),
    },
    {
      icon: "delete",
      tooltip: "Remover Grupo",
      onClick: (event, group) =>
        tableFunctions.setRemoveGroupModal(
          group,
          setCurrentGroup,
          setOpenRemoveGroup,
          openRemoveGroup
        ),
    },
  ];

  function saveGroup() {
    if (
      groupName !== currentGroup.group_name ||
      groupCode !== currentGroup.group_code ||
      groupDescription !== currentGroup.group_description
    ) {
      let body = {
        group_name: groupName,
        group_code: groupCode,
        group_description: groupDescription,
      };
      apiFunctions.Patch(
        api_configuration.api_route.groups_api + currentGroup.id,
        body,
        undefined,
        setLoading
      );
    }
    if (usersChecked.length > 0) {
      let body = usersChecked.map((usersChecked) => {
        return {
          user_id: usersChecked,
          group_id: currentGroup.id,
        };
      });
      apiFunctions.Post(
        api_configuration.api_route.users_group_multiple,
        body,
        undefined,
        setLoading
      );
    }
    if (removeUsersChecked.length > 0) {
      let body = removeUsersChecked.map((usersChecked) => {
        return {
          user_id: usersChecked,
          group_id: currentGroup.id,
        };
      });
      apiFunctions.Delete(
        api_configuration.api_route.users_group_multiple,
        body,
        undefined,
        setLoading
      );
    }
    if (indicatorsChecked.length > 0) {
      let body = indicatorsChecked.map((indicatorsChecked) => {
        return {
          indicator_id: indicatorsChecked,
          group_id: currentGroup.id,
        };
      });
      apiFunctions.Post(
        api_configuration.api_route.group_indicators_multiple,
        body,
        undefined,
        setLoading
      );
    }
    if (removeIndicatorsChecked.length > 0) {
      let body = removeIndicatorsChecked.map((indicatorsChecked) => {
        return {
          indicator_id: indicatorsChecked,
          group_id: currentGroup.id,
        };
      });
      apiFunctions.Delete(
        api_configuration.api_route.group_indicators_multiple,
        body,
        undefined,
        setLoading
      );
    }
  }

  let header_list;
  let title;

  title = "Grupo de Indicadores";
  header_list = [
    { field: "group_name", title: "Nome" },
    { field: "group_code", title: "Código" },
    { field: "group_description", title: "Descrição" },
  ];

  useEffect(() => {
    setGroupName(currentGroup.group_name);
    setGroupCode(currentGroup.group_code);
    setGroupDescription(currentGroup.group_description);
    tableFunctions.getAvaliableIndicators(
      getIndicatorById,
      currentGroup,
      setIndicatorsOptions,
      indicatorsData.length
    );
    setRemoveUsersOptions(
      tableFunctions.generateRemoveUsersChecked(usersGroup)
    );
    setUsersOptions(
      tableFunctions.generateCheckedUsersList(usersGroupData, usersGroup)
    );
    setUsersListData([tableFunctions.generateUsersList(usersGroup)]);

    setUsersGroup([]);
    // eslint-disable-next-line
  }, [currentGroup]);

  useEffect(() => {
    setRemoveUsersOptions(
      tableFunctions.generateRemoveUsersChecked(usersGroup)
    );
    setUsersOptions(
      tableFunctions.generateCheckedUsersList(usersGroupData, usersGroup)
    );
    setUsersListData([tableFunctions.generateUsersList(usersGroup)]);
    // eslint-disable-next-line
  }, [usersGroup]);

  const [collapseFields, setCollapseFields] = useState(true);
  const [collapseUsers, setCollapseUsers] = useState(true);
  const [collapseIndicators, setCollapseIndicators] = useState(true);
  const [collapseInfo, setCollapseInfo] = useState(true);

  let modalContent = (
    <>
      <p style={{ fontSize: "1.25rem", fontWeight: "600" }}>
        {currentGroup.group_description || ""}
      </p>
      <div style={{ height: "1rem" }} />
      <button
        type="button"
        onClick={() => setCollapseInfo(!collapseInfo)}
        className={`${userContext.contrast}indicator_collapsible_button_field`}
      >
        Ver Informações do Grupo{" "}
        <i
          className={collapseInfo ? "fas fa-caret-down" : "fas fa-caret-up"}
        ></i>
      </button>
      <div className={collapseInfo ? "collapse" : "show"}>
        <div style={{ height: "1rem" }} />
        <List
          title={""}
          divide={true}
          expandable={true}
          content={usersListData}
        />
        <div style={{ height: "1rem" }} />
        <List
          title={""}
          divide={true}
          expandable={true}
          content={groupIndicators}
        />
      </div>

      {currentGroup.id !== 1 && currentGroup.id !== 2 ? (
        <button
          type="button"
          style={{ marginTop: "1rem" }}
          onClick={() => setCollapseFields(!collapseFields)}
          className={`${userContext.contrast}indicator_collapsible_button_field`}
        >
          Editar Campos do Grupo{" "}
          <i
            className={collapseFields ? "fas fa-caret-down" : "fas fa-caret-up"}
          ></i>
        </button>
      ) : (
        <></>
      )}

      <div className={collapseFields ? "collapse" : "show"}>
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Nome"}
          labelText={"Nome do Grupo"}
          labelFor={"group_name"}
          inputId={"group_name"}
          inputValue={groupName}
          handleChange={(e) => setGroupName(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Código"}
          labelText={"Código do Grupo"}
          labelFor={"group_code"}
          inputId={"group_code"}
          inputValue={groupCode}
          handleChange={(e) => setGroupCode(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />

        <Input
          inputPlaceholder={"Descrição"}
          labelText={"Descrição do Grupo"}
          labelFor={"group_description"}
          inputId={"group_description"}
          inputValue={groupDescription}
          handleChange={(e) => setGroupDescription(e.target.value)}
          onBlur={() => {}}
        />
      </div>

      {currentGroup.id !== 1 && currentGroup.id !== 2 ? (
        <button
          type="button"
          style={{ marginTop: "1rem" }}
          onClick={() => setCollapseUsers(!collapseUsers)}
          className={`${userContext.contrast}indicator_collapsible_button_field`}
        >
          Editar Usuários do Grupo{" "}
          <i
            className={collapseUsers ? "fas fa-caret-down" : "fas fa-caret-up"}
          ></i>
        </button>
      ) : (
        <> </>
      )}
      <div className={collapseUsers ? "collapse" : "show"}>
        {usersGroup.length > 0 ? (
          <>
            <div style={{ height: "1rem" }} />
            {/*StyledListbox for change color popper  */}
            <CustomSelectFilters
              options={usersOptions}
              label={"Adicionar Usuários"}
              placeholder={"Selecione os usuários a serem adicionados ao grupo"}
              handleChange={setUsersChecked}
            />
            <div style={{ height: "1rem" }} />
            <CustomSelectFilters
              options={removeUsersOptions}
              label={"Remover Usuários"}
              placeholder={"Selecione os usuários a serem removidos do grupo"}
              handleChange={setRemoveUsersChecked}
            />
          </>
        ) : (
          <p>Não há usuários nesse grupo</p>
        )}
      </div>

      <button
        type="button"
        style={{ marginTop: "1rem" }}
        onClick={() => setCollapseIndicators(!collapseIndicators)}
        className={`${userContext.contrast}indicator_collapsible_button_field`}
      >
        Editar Indicadores do Grupo{" "}
        <i
          className={
            collapseIndicators ? "fas fa-caret-down" : "fas fa-caret-up"
          }
        ></i>
      </button>
      <div className={collapseIndicators ? "collapse" : "show"}>
        {indicatorsOptions.length > 0 ? (
          <>
            <CustomSelectFilters
              options={indicatorsOptions}
              label={"Adicionar Indicadores"}
              placeholder={
                "Selecione os indicadores a serem adicionados ao grupo"
              }
              handleChange={setIndicatorsChecked}
            />
            <div style={{ height: "1rem" }} />
          </>
        ) : (
          <p>Não há indicadores disponíveis para adicionar</p>
        )}

        {removeIndicatorsOptions.length > 0 && currentGroup.id !== 1 ? (
          <>
            <CustomSelectFilters
              options={removeIndicatorsOptions}
              label={"Remover Indicadores"}
              placeholder={
                "Selecione os indicadores a serem removidos do grupo"
              }
              handleChange={setRemoveIndicatorsChecked}
            />
          </>
        ) : currentGroup.id !== 1 ? (
          <p>Não há indicadores disponíveis para remover</p>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  return (
    <div className={`user-container ${userContext.contrast}`}>
      <MaterialTable
        title={title}
        columns={header_list}
        data={data}
        actions={acts}
        options={opts}
        localization={local}
        editable={false}
        style={Tstyle}
        icons={icons}
      />
      <FilterModal
        open={openRemoveGroup}
        onSave={() => {
          tableFunctions.deleteGroup(data, setData, currentGroup);
        }}
        label={"Remover"}
        icon={"fas fa-minus-circle"}
        onHandle={() => {
          setOpenRemoveGroup(!openRemoveGroup);
        }}
        description={<p>Deseja remover o grupo?</p>}
      />
      <FilterModal
        open={openOptionsModal}
        onSave={() => {
          saveGroup();
        }}
        label={"Salvar"}
        icon={"none"}
        onHandle={() => {
          setOpenOptionsModal(!openOptionsModal);
        }}
        description={modalContent}
      />
    </div>
  );
}

export default GroupsTable;
