import React, { useContext, useEffect } from "react";
import UserContext from "../../../Store/user-context";
import "./error404.css";

function Error404() {
    const userContext = useContext(UserContext);

    useEffect(() => {
        userContext.setBreadLocation([{ name: "Página 404", href: "/404", clickable: false }])
    }, [])

    return (
        <div className="error-container">
            <div className="error-text">
                <div>
                    <h1>
                        404
                    </h1>
                </div>
                <div>
                    <h5>
                        Página não encontrada
                    </h5>
                </div>
            </div>
        </div>
    );
}


export default Error404;