import React, { useContext, useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import MultiSelectUnstyled from "@mui/base/MultiSelectUnstyled";
import { selectUnstyledClasses } from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import "./CustomSelect.css";
import UserContext from "../Store/user-context";
import * as selectFunctions from "./CustomSelectFunctions";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  box-sizing: border-box;
  line-height: var(--input-medium);
  height: 40px;
  width: 100%;
  background: ${
    localStorage.getItem("contrast") === "contrast"
      ? "var(--color-primary-darken-02)"
      : "#fff"
  };
  border: 1px solid #888;
  border-radius: 6px;
  padding-left: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-2x);
  text-align: left;
  color: ${
    localStorage.getItem("contrast") === "contrast"
      ? "var(--color-secondary-01)"
      : grey[900]
  };

  &:hover {
    background: ${
      localStorage.getItem("contrast") === "contrast" ? "" : grey[100]
    };
    border-color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[700] : grey[400]
    };
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${
      localStorage.getItem("contrast") === "contrast" ? "" : blue[100]
    };
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  color: var(--color-primary-default);
  background-color: ${
    localStorage.getItem("contrast") === "contrast"
      ? "var(--color-primary-darken-02)"
      : "#fff"
  };
  border: 1px solid ${
    localStorage.getItem("contrast") === "contrast" ? grey[800] : grey[300]
  };
  border-radius: 0.75em;
  color: ${
    localStorage.getItem("contrast") === "contrast" ? grey[100] : grey[900]
  };
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  
  &.${optionUnstyledClasses.selected} {
    background-color: ${
      localStorage.getItem("contrast") === "contrast"
        ? "var(--color-primary-default)"
        : blue[900]
    };
    color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[100] : "#fff"
    };
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${
      localStorage.getItem("contrast") === "contrast"
        ? "var(--color-primary-darken-02)"
        : grey[100]
    };
    color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[300] : grey[900]
    };
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${
      localStorage.getItem("contrast") === "contrast"
        ? "var(--color-primary-default)"
        : blue[900]
    };
    color: ${
      localStorage.getItem("contrast") === "contrast" ? blue[100] : "#fff"
    };
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[700] : grey[400]
    };
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[600] : grey[500]
    };
    color: ${
      localStorage.getItem("contrast") === "contrast" ? grey[900] : grey[900]
    };
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  width: 100%;
  padding-right: 4%;
  padding-left: 4%;
  position: relative;
`;

const CustomMultiSelect = React.forwardRef(function CustomMultiSelect(
  props,
  ref
) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <MultiSelectUnstyled {...props} ref={ref} components={components} />;
});

CustomMultiSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */

  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

function BuscaRegiao(id, vetor, low, high) {  
  if (low > high) return 0;
  let middleIndex = Math.ceil((high - low) / 2);
  if (vetor[middleIndex].id === id) return middleIndex;
  if (vetor[middleIndex].id < id)
    return BuscaRegiao(id, vetor, middleIndex + 1, high + 1);
  return BuscaRegiao(id, vetor, low, middleIndex - 1);
}

function selectDefaultRegions(defaultValue, options, setSelected) {
  let regionsSelected = [];
  for (let i = 0; i < defaultValue.length; i++) {
    const selectedId = defaultValue[i];
    regionsSelected[i] = BuscaRegiao(selectedId, options, 0, options.length + 1);
  }
  return regionsSelected;
}

function buscaUf(vetor, uf){
  for(let i = 0; i < vetor.length; i++){
    const estado = vetor[i]
    if(estado.name === uf){
      return i;

    }
  }
  return null;
}

function selectDefaultUf(defaultValue, options, setSelected) {
  
  let ufSelected = [];
  for (let i = 0; i < defaultValue.length; i++) {
    ufSelected[i] = buscaUf(options, defaultValue[i]);
  }
  return ufSelected;
}

const CustomSelect = (props) => {
  let opt = props.options;
  let defaultValue = props.defaultValue || [];
  let label = props.label || "label padrão";
  let placeholder = props.placeholder || "placeholder padrão";
  let filterName = props.filterName || "";
  let onChange = props.handleChange || function () {};
  const [selected, setSelected] = useState([]);
  const options = opt;
  const userContext = useContext(UserContext);
  const selectFunctionHash = {
    "Por Região":
      defaultValue.length > 0
        ? () => selectDefaultRegions(defaultValue, options)
        : function () {},
    "Por UF":
      defaultValue.length > 0
        ? () => selectDefaultUf(defaultValue, options)
        : function () {},
    "Por Mesorregião":
      defaultValue.length > 0
        ? () => selectDefaultRegions(defaultValue, options)
        : function () {},
    "Por Cidade":
      defaultValue.length > 0
        ? () => selectDefaultUf(defaultValue, options)
        : function () {},
    "": function () {},
  };

  useEffect(() => {
    let selected = selectFunctionHash[filterName]();
    if (selected !== undefined) setSelected(selected);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="custom-select">
      <label className="custom-select-label"> {label} </label>
      <CustomMultiSelect
        theme={userContext.contrast ? "dark" : "light"}
        defaultValue={defaultValue}
        renderValue={(selected) =>
          selectFunctions.renderSelect(selected, placeholder)
        }
        onChange={(index) => {
          selectFunctions.onChangeOption(index, options, onChange, setSelected);
        }}
        value={selected}
      >
        <StyledOption value={selected.length === options.length ? -2 : -1}>
          {selected.length === options.length
            ? "Desselecionar Todos"
            : "Selecionar Todos"}
        </StyledOption>
        {options &&
          React.Children.toArray(
            options.map((option, index) => {
              return <StyledOption value={index}>{option.name}</StyledOption>;
            })
          )}
      </CustomMultiSelect>
    </div>
  );
};

export default memo(CustomSelect);
