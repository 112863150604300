/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import api_configuration from "../../../api_configuration";
import * as apiFunctions from "../../../Store/apiFunctions";
export function viewGroupUsers(
  setCurrentGroup,
  group,
  setOpenUsersModal,
  setUsersGroup,
  setGroupIndicators,
  setRemoveIndicatorsChecked
) {
  setCurrentGroup(group);
  getUsersData(setUsersGroup, group.id);
  generateIndicatorsList(group, setGroupIndicators);
  generateIndicatorsChecked(group, setRemoveIndicatorsChecked);
  setOpenUsersModal(true);
}

export function setGroupDescriptionModal(
  group,
  setCurrentGroup,
  setGroupIndicators,
  setOpenGroupDescription,
  openGroupDescription
) {
  setCurrentGroup(group);
  generateIndicatorsList(group, setGroupIndicators);
  setOpenGroupDescription(!openGroupDescription);
}

export function setRemoveGroupModal(
  group,
  setCurrentGroup,
  setOpenRemoveGroup,
  openRemoveGroup
) {
  setCurrentGroup(group);
  setOpenRemoveGroup(!openRemoveGroup);
}

export function setRemoveIndicatorModal(
  group,
  setCurrentGroup,
  setRemoveIndicatorsChecked,
  openRemoveIndicator,
  setOpenRemoveIndicator
) {
  setCurrentGroup(group);
  generateIndicatorsChecked(group, setRemoveIndicatorsChecked);
  setOpenRemoveIndicator(!openRemoveIndicator);
}

export function setAddIndicatorModal(
  group,
  setCurrentGroup,
  openAddIndicator,
  setOpenAddIndicator
) {
  setCurrentGroup(group);
  setOpenAddIndicator(!openAddIndicator);
}

export function setOpenOptionsModal(openOptionsModal, setOpenOptionsModal) {
  setOpenOptionsModal(!openOptionsModal);
}

/* set usersGroupData with users objects with name and role */
export function treatUsersGroupData(usersGroupData, setUsersGroupData) {
  let newUsersGroupData = [];
  let userObj = {};
  for (let i = 0; i < usersGroupData.length; i++) {
    const user = usersGroupData[i];
    userObj = {
      id: user.id,
      name: user.nome,
      role: user.role_name,
      permission: user.permission,
    };
    newUsersGroupData[i] = userObj;
  }
  setUsersGroupData(newUsersGroupData);
}

export function generateIndicatorsList(data, setGroupIndicators) {
  let indicatorsList = [];
  let newIndicators = [];
  newIndicators = data["indicators"].flatMap((name) => {
    return name;
  });
  for (let i = 0; i < newIndicators.length; i++) {
    const element = {
      id: i,
      primaryText: newIndicators[i]["indicator_name"],
      secondaryText: "",
      icon: "fas fa-building",
    };
    indicatorsList.push(element);
  }
  let listObj = [
    {
      label: "Indicadores",
      list: indicatorsList,
      total: indicatorsList.length,
    },
  ];
  setGroupIndicators(listObj);
}

export function getUsersData(setUsersGroup, id) {
  fetch(api_configuration.api_route.users_group_api + id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token")).token,
    },
  }).then(async (response) => {
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const responseData = isJson && (await response.json());
    if (response.ok) {
      setUsersGroup(responseData);
    } else {
      const error = response.statusText;
      return Promise.reject(error);
    }
  });
}

export function generateUsersList(usersGroup) {
  let userslist = usersGroup.map((user, index) => {
    return {
      id: index,
      primaryText: `${user.name} - ${user.role}`,
      secondaryText: "",
      icon: "fas fa-user",
    };
  });
  let listObj = {
    label: "Usuários",
    list: userslist,
    total: userslist.length,
  };
  return listObj;
}

export function generateNotesList(userData, setLoading) {
  let notesList = userData.notesArray.map((noteObj, index) => {
    return {
      id: index,
      primaryText: noteObj.note,
      secondaryText: "",
      icon: "fas fa-minus-circle",
      onClick: () => {
        apiFunctions.Delete(
          api_configuration.api_route.user_notes_api + noteObj.id,
          undefined,
          undefined,
          setLoading
        );
      },
    };
  });
  let listObj = {
    label: "Anotações",
    list: notesList,
    total: notesList.length,
  };
  return listObj;
}

export function generateCheckedUsersList(usersGroupData, usersGroup) {
  let flag;
  let checkedUsers = usersGroupData.map((user) => {
    flag = false;
    for (let i = 0; i < usersGroup.length; i++) {
      const groupUser = usersGroup[i];
      if (user.id === groupUser.user_id) {
        flag = true;
      }
    }
    if (!flag && user.permission === 2) {
      return {
        name: user.name,
        role: user.role,
        id: user.id,
        checked: false,
      };
    }
    return undefined;
  });
  checkedUsers = checkedUsers.filter((e) => e !== undefined);
  return checkedUsers;
}

export function generateRemoveUsersChecked(usersGroup) {
  let checkedRemoveUsers = usersGroup.map((user) => {
    if (user.role === "Técnico") {
      return {
        name: user.name,
        role: user.role,
        id: user.user_id,
        checked: false,
      };
    } else return;
  });
  checkedRemoveUsers = checkedRemoveUsers.filter((e) => e !== undefined);
  return checkedRemoveUsers;
}

export function generateIndicatorsChecked(data, setRemoveIndicatorsChecked) {
  let checkedArray = data["indicators"].flatMap((indicator) => {
    return {
      id: indicator["id"],
      name: indicator["indicator_name"],
      checked: false,
    };
  });
  setRemoveIndicatorsChecked([...checkedArray]);
}

export function getAvaliableIndicators(
  getIndicator,
  currentGroup,
  setIndicatorsChecked,
  indicatorsAmount
) {
  let availables = [];
  let flag;
  for (let i = 1; i <= indicatorsAmount; i++) {
    flag = 0;
    if (getIndicator(i) != null) {
      const element = { ...getIndicator(i), id: i };
      for (let i = 0; i < currentGroup["indicators"].length; i++) {
        const ind = currentGroup["indicators"][i];
        if (element["indicator_code"] === ind["indicator_code"]) flag = 1;
      }
      if (!flag) availables.push(element);
    } else availables.push(null);
  }
  let newavailables = availables.filter((indicators) => indicators != null);
  let checkedArray = newavailables.flatMap((indicator) => {
    return {
      id: indicator["id"],
      name: indicator["indicator_name"],
      checked: false,
      indicator: indicator,
    };
  });
  setIndicatorsChecked([...checkedArray]);
}
/* function that create a new group and adds it in data array */
export function createNewGroup(data, setData) {
  let newData = [...data];
  let newGroup = {
    group_name: "Nome do Grupo",
    group_description: "Descricao do Grupo",
    group_code: "Código do Grupo",
  };
  apiFunctions.Post(api_configuration.api_route.groups_api, newGroup);
  newGroup.id = newData.length + 1;
  newGroup.indicators = [];
  newGroup.tableData = { id: newData.length + 1 };
  newData.push(newGroup);
  setData(newData);
}

export function deleteGroup(data, setData, currentGroup) {
  let newData = [...data];
  for (let i = 0; i < data.length; i++) {
    const dataGroup = data[i];
    if (dataGroup["id"] === currentGroup["id"]) {
      newData.splice(i, 1);
    }
  }
  apiFunctions.Delete(api_configuration.api_route.groups_api + currentGroup.id);
  setData(newData);
}

/* set the current group with new indicators added */
export function onSaveAdd(
  currentGroup,
  setCurrentGroup,
  indicatorsChecked,
  setLoading
) {
  let newGroup = { ...currentGroup };
  let checked = [];
  for (let i = indicatorsChecked.length - 1; i >= 0; i--) {
    const indicator = indicatorsChecked[i];
    if (indicator["checked"]) {
      checked.push({ group_id: currentGroup.id, indicator_id: indicator.id });
      let lastId = 0;
      if (currentGroup["indicators"].length) {
        lastId =
          currentGroup["indicators"][currentGroup["indicators"].length - 1][
            "id"
          ];
      }
      indicator["indicator"]["id"] = lastId + 1;
      newGroup["indicators"].push({
        ...indicator["indicator"],
      });
    }
  }
  //usar rota POST g-indicator/multiple
  apiFunctions.Post(
    api_configuration.api_route.group_indicators_multiple,
    checked,
    undefined,
    setLoading
  );
  setCurrentGroup(newGroup);
}

/* function handle new users added in the new group */
export function onSaveAddUser(
  usersGroup,
  setUsersGroup,
  usersChecked,
  currentGroup,
  setLoading
) {
  let newUsersGroup = [...usersGroup];
  let body = [];
  let newBodyUser = {};
  let newGroupUser = {};
  for (let i = 0; i < usersChecked.length; i++) {
    const user = usersChecked[i];
    if (user.checked) {
      newBodyUser = {
        user_id: user.id,
        group_id: currentGroup.id,
      };
      newGroupUser = {
        name: user.name,
        role: user.role,
      };
      body.push(newBodyUser);
      newUsersGroup.push(newGroupUser);
    }
  }
  apiFunctions.Post(
    api_configuration.api_route.users_group_multiple,
    body,
    undefined,
    setLoading
  );
  setUsersGroup(newUsersGroup);
}

export function onSaveRemoveUser(
  usersGroup,
  setUsersGroup,
  usersChecked,
  currentGroup,
  setLoading
) {
  let newUsersGroup = [...usersGroup];
  let body = [];
  let newBodyUser = {};
  for (let i = 0; i < usersChecked.length; i++) {
    const user = usersChecked[i];
    if (user.checked) {
      newBodyUser = {
        user_id: user.id,
        group_id: currentGroup.id,
      };
      body.push(newBodyUser);
      newUsersGroup.splice(i, 1);
    }
  }
  apiFunctions.Delete(
    api_configuration.api_route.users_group_multiple,
    body,
    undefined,
    setLoading
  );
  setUsersGroup(newUsersGroup);
}

/* on save action */
export function onSaveRemove(
  currentGroup,
  setCurrentGroup,
  removeIndicatorsChecked,
  setLoading
) {
  let removeGroups = [];
  for (let j = 0; j < removeIndicatorsChecked.length; j++) {
    const checked = removeIndicatorsChecked[j];
    if (checked["checked"]) {
      removeGroups.push({
        group_id: currentGroup["id"],
        indicator_id: checked["id"],
      });
    }
  }
  apiFunctions.Delete(
    api_configuration.api_route.group_indicators_multiple,
    removeGroups,
    undefined,
    setLoading
  );
  let newGroup = { ...currentGroup };
  for (let i = newGroup["indicators"].length - 1; i >= 0; i--) {
    const indicator = newGroup["indicators"][i];
    for (let j = 0; j < removeIndicatorsChecked.length; j++) {
      const checked = removeIndicatorsChecked[j];
      if (indicator["id"] === checked["id"] && checked["checked"]) {
        newGroup["indicators"].splice(i, 1);
      }
    }
  }
  setCurrentGroup(newGroup);
}

export function handleChangeAddorRemove(
  indicatorsChecked,
  setIndicatorsChecked,
  checkboxIndex
) {
  const checkedArray = [...indicatorsChecked];
  checkedArray[checkboxIndex].checked = !checkedArray[checkboxIndex].checked;
  setIndicatorsChecked([...checkedArray]);
}

export function createNewIndicator(data, setData) {
  let newData = [...data];
  let dt = new Date();
  let newIndicator = {
    indicator_code: JSON.stringify(Number(data[data.length - 1].id) + 1),
    indicator_description: "Descrição",
    indicator_source: "Fonte do Indicador",
    indicator_presentation_shape: "Formato",
    indicator_name: "Nome do Indicador",
    indicator_route: "rota",
    last_update: dt.toISOString(),
    label: "Label",
  };
  apiFunctions.Post(api_configuration.api_route.indicators_api, newIndicator);
  newIndicator.tableData = { id: newData.length };
  newIndicator.id = newIndicator.indicator_code;
  newIndicator.filters = [];
  newIndicator.dims = [];
  newData.push(newIndicator);
  setData(newData);
}

export function deleteInicator(data, setData, indicator) {
  let newData = [...data];
  apiFunctions.Delete(
    api_configuration.api_route.basic_indicators_api + indicator.id
  );

  setData(newData);
}

export function createNewDimension(
  dims,
  setDims,
  setShouldUpdateDims,
  shouldUpdateDims
) {
  let newData = [...dims];
  let newDims = {
    dimension_description: "Descrição",
    dimension_name: "Nome",
    dimension_api_callable: "Rota",
  };
  apiFunctions.Post(api_configuration.api_route.dims_api, newDims);
  newDims.tableData = {
    id: dims.length + 1,
  };
  setShouldUpdateDims(!shouldUpdateDims);
  newData.push(newDims);
  setDims(newData);
}

export function createNewFilter(
  filters,
  setFilters,
  setShouldUpdateFilters,
  shouldUpdateFilters
) {
  let newData = [...filters];
  let newFilter = {
    filter_description: "Descrição",
    filter_name: "Nome",
    filter_api_callable: "Rota",
  };
  apiFunctions.Post(api_configuration.api_route.filters_api, newFilter);
  newFilter.tableData = {
    id: filters.length + 1,
  };
  setShouldUpdateFilters(!shouldUpdateFilters);
  newData.push(newFilter);
  setFilters(newData);
}

export {};
