/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React from "react";
import {
  Edit,
  Search,
  NavigateBefore,
  NavigateNext,
  SaveAlt,
  Check,
  Clear,
  Info,
  Add,
  Remove,
  Delete,
  Group,
  FilterList,
  Settings,
} from "@material-ui/icons";

const retrieveIcons = (contrast) => {
  const Icons = {
    add: React.forwardRef((props, ref) => (
      <Add
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    settings: React.forwardRef((props, ref) => (
      <Settings
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    group: React.forwardRef((props, ref) => (
      <Group
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    delete: React.forwardRef((props, ref) => (
      <Delete
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    remove: React.forwardRef((props, ref) => (
      <Remove
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Filter: React.forwardRef((props, ref) => (
      <FilterList
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    info: React.forwardRef((props, ref) => (
      <Info
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Edit: React.forwardRef((props, ref) => (
      <Edit
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Search: React.forwardRef((props, ref) => (
      <Search
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    PreviousPage: React.forwardRef((props, ref) => (
      <NavigateBefore
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    NextPage: React.forwardRef((props, ref) => (
      <NavigateNext
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Export: React.forwardRef((props, ref) => (
      <SaveAlt
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Check: React.forwardRef((props, ref) => (
      <Check
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
    Clear: React.forwardRef((props, ref) => (
      <Clear
        style={
          contrast
            ? { color: "var(--color-secondary-01)" }
            : { color: "var(--color-primary-default)" }
        }
        {...props}
        ref={ref}
      />
    )),
  };
  return Icons;
};

function retrieveOpts(Tstyle, toggleSearch) {
  const opts = {
    filtering: true,
    selection: false,
    emptyRowsWhenPaging: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    addRowPosition: "first",
    draggable: false,
    showFirstLastPageButtons: false,
    search: toggleSearch,
    exportButton: true,
    exportAllData: true,
    headerStyle: Tstyle,
  };
  return opts;
}

function retrieveActionSearch(userContext) {
  const actionSearch = React.forwardRef((props, ref) => (
    <Search
      style={
        userContext.contrast
          ? { color: "var(--color-secondary-01)" }
          : { color: "var(--color-primary-default)" }
      }
      {...props}
      ref={ref}
    />
  ));
  return actionSearch;
}

const Local = {
  header: {
    actions: "",
  },
  toolbar: {
    searchTooltip: "Busca",
    searchPlaceholder: "Busca",
    nRowsSelected: "{0} Linha(s) selecionadas",
    exportTitle: "Exportar",
    exportName: "Exportar como CSV",
  },
  body: {
    addTooltip: "Adicionar",
    editTooltip: "Editar",
    deleteTooltip: "Remover",
    emptyDataSourceMessage: "Sem dados para exibir",
    filterRow: {
      filterTooltip: "Filtro",
      filterPlaceHolder: "Digite para filtrar",
    },
    editRow: {
      deleteText: "Deseja realmente remover este usuário?",
      cancelTooltip: "Cancelar",
      saveTooltip: "Confirmar",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count} itens",
    labelRowsSelect: "Linhas",
    labelRowsPerPage: "Linhas por página",
    firstAriaLabel: "Primeira página",
    firstTooltip: "Primeira página",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Próxima página",
    nextTooltip: "Próxima página",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
  },
};

function retrieveStyle(contrast) {
  const Tstyle = contrast
    ? {
        backgroundColor: "var(--color-primary-darken-02)",
        color: "var(--color-secondary-01)",
      }
    : {};
  return Tstyle;
}

export {
  retrieveStyle,
  Local,
  retrieveIcons,
  retrieveOpts,
  retrieveActionSearch,
};
