/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRMenu from './js-dsgov/Menu';
import './Styles/Menu.css';
import { useContext, useState, useEffect } from 'react';
import UserContext from "../../Store/user-context";



function Menu(props) {

  //LAST: No caso de ser necessario modo 2 descomentar função que fecha o menu em clique e ver o erro de clique duplo

  // eslint-disable-next-line
  const [menuList, setMenuList] = useState([])

  let mode = props.mode ? props.mode : '1'
  let items = props.items ? props.items : [] //[ 'nome grupo': nome, items:[{nome:nome,link:link}]     ]
  // let top_image = props.top_image ? props.top_image : ''
  let logos = props.logos ? props.logos : []
  // let links = props.links ? props.links : []
  let social = props.social ? props.social : [] //[ {icon:icon,link:link,label:label} , {}   ]
  let social_title = props.social_title ? props.social_title : 'MÍDIAS SOCIAIS'
  let menu_info = props.menu_info ? props.menu_info : <div className="text-center text-down-01">Todo o conteúdo deste site está publicado sob a licença <strong>Creative Commons Atribuição-SemDerivações 3.0</strong>
  </div>
  let density = props.density ? props.density : 'medium' //small, medium, large
  let menu_title = props.menu_title ? props.menu_title : 'Titulo padrão'
  let menu_id = props.menu_id ? props.ment_id : 'main-navigation'
  let menu_user = props.menu_user ? props.menu_user : '0'
  let contrast = props.contrast ? props.contrast : '';
  const userContext = useContext(UserContext);
  const menuListTemp = []
  useEffect(() => {
    for (const brMenu of window.document.querySelectorAll('.br-menu:not(.dsgov)')) {
      menuListTemp.push(new BRMenu('br-menu', brMenu))
    }
    setMenuList(menuListTemp)
    // eslint-disable-next-line
  }, [])

  if (mode === '1') {
    return (
      <div className="col-sm">
        <div className="d-flex align-items-center">
          <div className={"br-menu " + density} id={menu_id}>
            <div className="menu-container">
              <div className="row">
                <div className="menu-panel">
                  <div className="menu-header" style={contrast === "contrast" ? { "backgroundColor": "var(--color-primary-darken-01)" } : {}}>
                    <div className="menu-close">
                      <button className="br-button circle" type="button" arial-label="Fechar o menu" data-dismiss="menu">
                        <i className="fas fa-times" style={contrast === "contrast" ? { "color": "var(--color-secondary-01)", "fontSize": "25px" } : { "fontSize": "25px" }} aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="menu-title" style={contrast === "contrast" ? { "color": "white" } : { "color": "#1351B4" }}>
                      {/* {top_image} */}
                      <p>Menu</p>
                    </div>
                  </div>
                  <nav className="menu-body" style={contrast === "contrast" ? { "backgroundColor": "var(--color-primary-darken-01)" } : {}}>
                    {
                      items.map(item =>
                        <div key={"MENU1" + String(Math.random())} className='menu-folder'>
                          <a className='menu-item' href={item.group_link}>
                            <span className='content_sidebar' style={contrast === "contrast" ? { "color": "var(--color-secondary-01)" } : {}}>{item.icon}{item.group_name}</span>
                          </a>
                          < span >
                            {
                              item.content.map(cont =>
                                <li key={"MENU01" + String(Math.random())}>
                                  <a className='menu-item' href={cont.link}>
                                    <span className='content' style={contrast === "contrast" ? {
                                      "color": "var(--color-secondary-01)"
                                    } : {}}>{cont.name}</span>
                                  </a>
                                  {cont.submenu}
                                </li>
                              )
                            }
                          </span>
                        </div>
                      )
                    }
                    <div style={menu_user === '0' ? { "display": 'none' } : {}}>
                      <div className={`${contrast}menu-folder-2`}>
                        <div className='menu-item'>
                          <button
                            className="button-menu-logout content_sidebar"
                            type="button"
                            data-panel="notification-panel-1-27981"
                            onClick={() => userContext.onHandleLogOut()}
                          >
                            <span className={`${contrast}name`} >
                              <span className="icon mb-1 mb-sm-0 mr-sm-1">
                                <i className="fas fa-sign-out-alt" style={{ fontSize: "20px", marginRight: "30px" }}></i>
                              </span>
                              <span className={`${contrast}name`} >Sair</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="menu-footer" style={contrast === "contrast" ? { "backgroundColor": "var(--color-primary-darken-01)" } : {}}>
                    {
                      logos &&
                      <div className="menu-logos">
                        {
                          logos.map(logo =>
                            <div key={"MENU2" + String(Math.random())} style={contrast === "contrast" ? { "color": "var(--color-secondary-01)" } : {}}>
                              logo
                            </div>
                          )
                        }
                      </div>
                    }
                    {/* <div className="menu-links">
                      {
                        links.map(link =>
                          <a style={contrast === "contrast" ? { "color": "var(--color-secondary-01)", "textDecoration": "underline" } : {}} key={"MENU3" + String(Math.random())} href={link.link}>{link.nome}&nbsp;
                            <i className="fas fa-external-link-square-alt" aria-hidden="true"></i>
                          </a>
                        )
                      }
                    </div> */}
                    <div className="menu-social">
                      <div className="text-semi-bold mb-2" style={contrast === "contrast" ? { "color": "var(--color-secondary-01)", "marginLeft": "4.5px", "fontSize": "16px", "fontWeight": "700" } : { "marginLeft": "4.5px", "fontSize": "16px", "fontWeight": "700" }}>{social_title}</div>
                      <div className="sharegroup">
                        {
                          social.map(social =>
                            <div key={"MENU4" + String(Math.random())} className="share">
                              <a style={contrast === "contrast" ? { "color": '#ffffff' } : { "color": '#2d3136' }} className="br-button circle" href={social.link} aria-label={social.aria_label}>
                                {social.icon}
                              </a>
                            </div>
                          )
                        }
                        <div className="box-instagram-menu" style={contrast === "contrast" ? { "backgroundColor": "#ffffff" } : { "backgroundColor": "#2d3136" }}>
                          <a className="br-button circle" style={contrast === "contrast" ? { "backgroundColor": "trasparent" } : { "backgroundColor":"transparent" }} href={"https://www.instagram.com/accounts/login/?next=/fnde.oficial/"} >
                            <i class="fab fa-instagram " style={
                              contrast === "contrast"
                                ? { color: '#0C326F', fontSize: "20px" }
                                : { color: '#ffffff', fontSize: "20px" }
                            } />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="menu-info" style={contrast === "contrast" ? { "color": "var(--color-secondary-01)" } : {}}>
                      {menu_info}
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-scrim" data-dismiss="menu" tabIndex="0"></div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="header-menu">
              <div className="header-menu-trigger">
                <button className="br-button small circle" type="button" aria-label="Menu" data-toggle="menu" data-target="#main-navigation" id="menu-small"><i className="fas fa-bars" style={contrast === "contrast" ? { "color": "var(--color-secondary-01)" } : {}} aria-hidden="true"></i>
                </button>
              </div>
              <div className="header-info">
                <div className="header-title" style={contrast === "contrast" ? { "color": "var(--color-secondary-01)" } : { "color": "#1351B4" }}>{menu_title}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}


export default Menu;
