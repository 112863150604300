/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';
import UserContext from '../../../Store/user-context';

/* Parameters 
 * children: JSX */

function ModalComponent(props) {
  const userContext = useContext(UserContext);
  const children = props.children || <div></div>

  return ReactDOM.createPortal(
    <div className={`${userContext.contrast}Modal`}>
      {children}
    </div>,
    document.getElementById('modal-root')
  );
}


export default ModalComponent;
