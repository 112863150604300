export function validateCpf(cpf) {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
  cpf = cpf.split('')
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el)
  const toValidate = pop => cpf
    .filter((digit, index, array) => index < array.length - pop && digit)
    .map(el => +el)
  const rest = (count, pop) => (toValidate(pop)
    .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
}

export function validateEmail(email) {
  const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return re.test(String(email).toLowerCase());
}

export function validateName(string) {
  let regex = new RegExp("([a-zA-Z ]{1,}$)");

  return regex.test(string);
}

export function isStringEightCharsLonger(string) {
  let regex = new RegExp("(?=.{8,})");

  return regex.test(string);
}

export function validateDate(date) {
  const today = new Date();
  const birthDate = new Date(date);
  let regex = new RegExp(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/);

  if (birthDate > today || birthDate.getFullYear() < 1900) return false;

  return regex.test(date);
}
