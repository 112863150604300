import React from "react";
import PersonalizedDashboardComponent from "../Components/app/dashboard/personalized-dashboard/Dashboard";
function PersonalizedDashboard() {
  return (
    <>
      <PersonalizedDashboardComponent />
    </>
  );
}

export default PersonalizedDashboard;
