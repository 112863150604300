import React from "react";
import DashboardComponent from "../Components/app/dashboard/public-dashboard/Dashboard";

function Dashboard() {
  return (
    <>
      <DashboardComponent />
    </>
  );
}

export default Dashboard;
