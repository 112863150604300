import React from "react";
import RecoverComponent from "../Components/app/password-recovery/Recover";

function Dashboard() {
  return (
    <>
      <RecoverComponent />
    </>
  );
}

export default Dashboard;
