/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

export default class DashboardHelper {
  constructor() {
    this.indicators = [];
  }

  validate_keys(level, key) {
    const second_possible_keys = ["complete", "half", "empty"];
    const third_possible_keys = ["indicator_id", "left", "right"];
    switch (level) {
      case "second":
        for (let i = 0; second_possible_keys.length; i++) {
          if (second_possible_keys[i] === key) {
            return true;
          }
        }
        return false;
      case "third":
        for (let i = 0; third_possible_keys.length; i++) {
          if (third_possible_keys[i] === key) {
            return true;
          }
        }
        return false;
      default:
        return false;
    }
  }

  validate_fourth_level(json, third_level) {
    let fourth_level = Object.keys(json[third_level]);
    for (let i = 0; i < fourth_level.length; i++) {
      if (fourth_level[i] === "indicator_id") {
        let value = json[third_level][fourth_level[i]];
        if (value == null || typeof value === "number") {
          return "Valid";
        } else {
          return "Invalid";
        }
      } else {
        return "Invalid";
      }
    }
    return "Valid";
  }

  validate_third_level(json, second_level) {
    let third_level = Object.keys(json[second_level]);
    for (let i = 0; i < third_level.length; i++) {
      let valid_key = this.validate_keys("third", third_level[i]);
      if (valid_key) {
        if (third_level[i] === "indicator_id") {
          let value = json[second_level][third_level[i]];
          if (value == null || typeof value === "number") {
            return "Valid";
          } else {
            return "Invalid";
          }
        } else {
          let fourth = this.validate_fourth_level(
            json[second_level],
            third_level[i]
          );
          if (fourth !== "Valid") {
            return "Invalid";
          }
        }
      } else {
        return "Invalid";
      }
    }
    return "Valid";
  }

  validate_second_level(json, first_level) {
    let second_level = Object.keys(json[first_level]);
    for (let i = 0; i < second_level.length; i++) {
      let valid_key = this.validate_keys("second", second_level[i]);
      if (valid_key) {
        if (second_level[i] === "empty") {
          return "Valid";
        } else {
          let thrid = this.validate_third_level(
            json[first_level],
            second_level[i]
          );
          if (thrid !== "Valid") {
            return "Invalid";
          }
        }
      } else {
        return "Invalid";
      }
    }
    return "Valid";
  }

  validateJson(json) {
    let first_level = Object.keys(json);
    for (let i = 0; i < first_level.length; i++) {
      if (Number(first_level[i]) !== i + 1) {
        return "Invalid";
      } else {
        let second = this.validate_second_level(json, first_level[i]);
        if (second !== "Valid") {
          return "Invalid";
        }
      }
    }
    return "Valid";
  }

  retrieve(json) {
    let row = [];

    let first_level = Object.keys(json);
    for (let i = 0; i < first_level.length; i++) {
      let second_level = Object.keys(json[String(i + 1)]);
      for (let j = 0; j < second_level.length; j++) {
        switch (second_level[j]) {
          case "complete":
            row.push([json[String(i + 1)]["complete"]["indicator_id"]]);
            break;
          case "half":
            let temp = [];
            temp.push(json[String(i + 1)]["half"]["left"]["indicator_id"]);
            temp.push(json[String(i + 1)]["half"]["right"]["indicator_id"]);
            row.push(temp);
            break;
          case "empty":
            row.push([null]);
            break;
          default:
            break;
        }
      }
    }
    return row;
  }

  retrieve_indicators() {
    var indicator_order;
    var data = JSON.parse(localStorage.getItem("publicDashboard"))
    var dashboardData = this.treatResponse(data);
    dashboardData = JSON.parse(dashboardData);
    var response = this.validateJson(dashboardData);
    if (response) {
      indicator_order = this.retrieve(dashboardData);
    }
    this.indicators = indicator_order;
  }

  get indicator_order() {
    return this.indicators;
  }

  treatResponse(dashboard) {
    // Function to parse Items to Dashboard Template
    // Line Position:
    // 1: Left
    // 2: Right
    // 3: Complete
    const code_position = { 1: "left", 2: "right", 3: "complete" };
    var dashb = dashboard["items_id"] || [];
    var new_dashboard = {};
    var line_items_counter = 0;
    var current_line = "";
    dashb = dashb.sort((a, b) => (a.line_id > b.line_id ? 1 : -1));
    for (let i = 0; i < dashb.length; i++) {
      // Verify current line
      if (current_line !== dashb[i]["line_name"]) {
        current_line = dashb[i]["line_name"];
        line_items_counter = 1;
        if (!new_dashboard[current_line]) new_dashboard[current_line] = {};
      } else {
        line_items_counter += 1;
        // Check if there are more than 2 columns
        if (line_items_counter + 1 > 3) {
          // TODO: Do something with this error
        }
      }
      // Check if it is a complete line or a 2 column line
      if (dashb[i]["line_position"] < 3) {
        if (!new_dashboard[current_line]["half"]) {
          new_dashboard[current_line]["half"] = {};
        }
        new_dashboard[current_line]["half"][
          code_position[dashb[i]["line_position"]]
        ] = { indicator_id: dashb[i]["position_content"] };
      } else {
        new_dashboard[current_line]["complete"] = {
          indicator_id: dashb[i]["position_content"],
        };
      }
    }
    return JSON.stringify(new_dashboard);
  }
}

// export default DashboardHelper;
