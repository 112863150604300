/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRModal from './js-dsgov/Modal'; //NOTE: alterado função  _setBehavior() (importação sem webpack)
import { useContext } from 'react';
import UserContext from '../../Store/user-context';
import "./Styles/Modal.css";

/*
    **Parameters**
      - justifyFooter = string;
      - title = string;
      - body = JSX;
      - footer = JSX;
      - onCancelModal = function;
  */

function Modal(props) {
  const userContext = useContext(UserContext)

  const modalList = []
  for (const brModal of window.document.querySelectorAll('.br-modal')) {
    modalList.push(new BRModal('br-modal', brModal))
  }

  const justifyFooter = props.justifyFooter || "end";
  const title = props.title || "";
  const body = props.body || "";
  const footer = props.footer || "";
  const onCancelModal = props.onCancelModal || function () { };

  return (
    <div className="br-modal">
      <div className="container-fluid p-1 p-sm-4">
        <div className="br-modal-header">
          <div className="br-modal-title" title={title}>
            {title}
          </div>
          <button className={`${userContext.contrast === "contrast" && "inverted"} br-button close circle small`} type="button" data-dismiss="br-modal" aria-label="Close" onClick={onCancelModal}>
            <i className="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div className="br-modal-body">
          {body}
        </div>
        <div className={`br-modal-footer justify-content-${justifyFooter}`}>
          {footer}
        </div>
      </div>
    </div>
  )

}

export default Modal;
