export function getHexById(id, contrast) {
  if(contrast === "contrast") {
    return mdColors[id];
  } 
  return mdColors[id - 1];
}

export function selectColor(number) {
  const hue = number * 137.508; // use golden angle approximation
  return `hsl(${hue},80%,45%)`;
}

const mdColors = [
  "var(--color-primary-default)",
  "#77a8f7",
  "#eb3f69",
  "#dea92f",
  "#90c87d",
  "#b08edc",
  "#9a8f2e",
  "#c570c5",
  "#55cfa1",
  "#dd5f92",
  "#40becf",
  "#599954",
  "#e24cc3",
  "#4b9b66",
  "#b1773e",
  "#47a586",
  "#889450",
  "#e2a369",
  "#6a9bd8",
  "#c0be30",
  "#db7a29",
  "#34a03e",
  "#629835",
  "#c2be69",
  "#d9756b",
  "#51cf67",
  "#ea4f2f",
  "#af5eea",
  "#93c13a",
  "#d48bba",
  "#5ccc2f",
  "#ea8ab3",
  "#ea6f2c",
  "#ceba3b",
  "#d043e1",
  "#5976bc",
  "#56cde9",
  "#35832f",
  "#b2812d",
  "#6263c3",
  "#4a9ee3",
  "#db7948",
  "#6b6f37",
  "#c2a4df",
  "#cd6ee5",
  "#ab9a31",
  "#919be2",
  "#845dd8",
  "#eda596",
  "#e2ac4b",
  "#6b69b4",
  "#73d0b9",
  "#54d0a1",
  "#4ab02e",
  "#ad84e4",
  "#8e9648",
  "#db7a6a",
  "#a25779",
  "#43aa6a",
  "#d5b482",
  "#ea2f99",
  "#d99350",
  "#f1a379",
  "#ab7baf",
  "#e73b77",
  "#d64cab",
  "#628bef",
  "#7c9424",
  "#c24a18",
  "#9d42b7",
  "#6d6e23",
  "#9b41e1",
  "#76631e",
  "#e08d24",
  "#db7bcd",
  "#96c430",
  "#a84990",
  "#91baee",
  "#e5a4e8",
  "#947f41",
  "#5191bf",
  "#897917",
  "#3570c5",
  "#43b5ae",
  "#7a5b8d",
  "#3b6f9c",
  "#e33754",
  "#b4c078",
  "#a6541c",
  "#83bc70",
  "#8abe91",
  "#ac4e35",
  "#ea655f",
  "#729861",
  "#e6a1c1",
  "#a56e51",
  "#566196",
  "#bac153",
  "#3b5da0",
  "#d15b78",
  "#b83068",
  "#c28b69",
  "#e1619a",
  "#35a3bf",
  "#bec521",
  "#487549",
  "#827db9",
  "#b93443",
  "#9b955e",
  "#a55153",
  "#4cd176",
  "#ee4120",
  "#b728a2",
  "#256fae",
  "#95cc63",
  "#e6b427",
  "#54762d",
  "#318869",
  "#736332",
  "#8c59a8",
  "#d4b267",
  "#d17d8b",
  "#8a5c37",
  "#986324",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
  "#292961"
];